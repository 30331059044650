import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';

import bannerBG from '../../assets/img/hero/bg-1-1.jpg';
import shapeImg1 from '../../assets/img/contact/shape-1-7.png';
import shapeImg2 from '../../assets/img/hero/shape-2-2.png';
import shapeImg3 from '../../assets/img/hero/shape-2-3.png';
import shapeImg4 from '../../assets/img/hero/shape-2-4.png';
import shapeImg5 from '../../assets/img/hero/shape-2-5.png';
import bannerImg1 from '../../assets/img/hero/slider5-3.png';
import bannerImg2 from '../../assets/img/hero/thumb-2-2.png';
import studentImg from '../../assets/img/hero/student.png';

const Banner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="ed-hero-2-area ed-hero-2-bg fix z-index p-relative" style={{ backgroundImage: `url(${bannerBG})` }}>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="PO_fBTkoznc"
        onClose={() => openModal()}
      />
      <div className="ed-hero-2-shape-1">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="ed-hero-2-shape-2 d-none d-md-block">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="ed-hero-2-shape-3">
        <img src={shapeImg4} alt="" />
      </div>
      <div className="container container-3">
        <div className="row align-items-center">
          <div className="col-xxl-6 col-xl-5 col-lg-6">
            <div className="ed-hero-bg p-relative z-index">
              <h1
                className="ed-slider-title pb-5 wow animate__fadeInUp"
                data-wow-duration=".9s"
                data-wow-delay=".3s"
              >
                One Stop Solution<br />
                For All Academic Needs. 
              </h1>
              <div
                className="ed-hero-2-text mb-30 wow animate__fadeInUp"
                data-wow-duration=".9s"
                data-wow-delay=".5s"
              >
                <p>
                Central books core interest area is in providing services for academic requirements and
                catering to reputed educational organizations.
                </p>
              </div>
              <div
                className="ed-hero-2-button-wrapper wow animate__fadeInUp"
                data-wow-duration=".9s"
                data-wow-delay=".7s"
              >
                <div className="ed-hero-2-button d-flex align-content-center">
                  <Link className="ed-btn-radius" to="/about-us">
                  View All Services
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-7 col-lg-6">
            <div className="ed-hero-2-right p-relative">
              <div className="ed-hero-2-thumb style-2">
                <img src={bannerImg1} alt="" />
              </div>
              <div className="ed-hero-thumb-courses d-none d-md-block">
                <i>75+ Years</i>
                <span>of Educational Services</span>
              </div>
              <div className="ed-hero-2-shape-6">
                <img src={shapeImg5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
