import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import RightArrow from '../../components/SVG';

const Course = () => {
  const timelineItems = [
    {
      year: '1948',
      title: 'Start',
      description:
        "Established a retail store at Abids, with George's Grammar ICSE school as the first client.",
      color: '#F0601F',
    },
    {
      year: '1972',
      title: 'Growth',
      description:
        'Exclusive distributors for all National and MNC publishers for Andhra Pradesh.',
      color: '#8B36BE',
    },
    {
      year: '2000',
      title: 'Introduction to School Services',
      description:
        'A one-stop shop solution for parents of empaneled schools to purchase textbooks, notebooks, and stationery items through school tuck shops.',
      color: '#DE299A',
    },
    {
      year: '2008',
      title: 'Award',
      description:
        '"Outstanding Performance Award" from the Federation of Indian Publishers.',
      color: '#3931B9',
    },
    {
      year: '2010',
      title: 'Crossed 6 lakh Students',
      description:
        'Successfully served more than 600 schools and 6 lakh students.',
      color: '#199DFA',
    },
    {
      year: '2013',
      title: 'Ecommerce',
      description: "Set up India's first eCommerce platform for school supply.",
      color: '#6EB000',
    },
    {
      year: '2017',
      title: 'Award',
      description:
        'Best Performance Award in School Education from S.Chand Group',
      color: '#E41F44',
    },
    {
      year: '2017',
      title: 'Expansion - Mumbai',
      description:
        'Started Mumbai operations with online and offline tuck shop sales.',
      color: '#F2B21A',
    },
    {
      year: '2018',
      title: 'Tech Team',
      description:
        'Established in-house technology team to provide world-class service to our school partners.',
      color: '#0C418E',
    },
    {
      year: '2020',
      title: 'Served 18 lakh plus Students',
      description:
        'Ecommerce - 70k+\nOffline supply - 6L+\nB2B supply - 12L+',
      color: '#00BEAD',
    },
    {
      year: '2022',
      title: 'E-com Milestone',
      description:
        'Served 1 Lakh+ Students via online order delivery',
      color: '#F0601F',
    },
    {
      year: '2023',
      title: 'Content Development',
      description:
        'Started Content development in K-12 segment',
      color: '#8B36BE',
    },
    {
      year: '2024',
      title: 'Library Solutions & Cambridge Assessment',
      description:
        'Introduced Library Solutions for Schools \n Introduced Cambridge Assessment for Students',
      color: '#DE299A',
    },
  ];

  return (
    <div
      id="it-course"
      className="it-course-area ed-course-bg ed-course-style-3 p-relative pt-120 pb-90"
    >
    <div className="container">
      <div className="ed-course-title-wrap mb-65">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <SectionTitle
                itemClass="it-course-title-box"
                subTitleClass="ed-section-subtitle"
                subTitle="Our Success"
                titleClass="ed-section-title"
                title="Serving for over 75 years."
              />
            </div>
            <div className="col-xl-6">
              <div className="ed-course-button text-lg-end">
                <Link className="ed-btn-square orange" to="/services">
                  Our Services
                  <i>
                    <RightArrow />
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-12">
        <h1 class="text-4xl font-bold text-blue-600">Our Timeline</h1>
        <p class="text-xl mt-2 text-gray-500">Serving since 1948</p>
        </div>
      <div className="timeline">
        {timelineItems.map((item, index) => (
          <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
            <div className="timeline-content">
              <div className="timeline-year" style={{ color: item.color }}>
                {item.year}
              </div>
              <h3 className="timeline-title" style={{ color: item.color }}>
                {item.title}
              </h3>
              <p className="timeline-description">{item.description}</p>
            </div>
            <div
            className={`timeline-dot ${index % 2 === 0 ? 'timeline-dot-left' : 'timeline-dot-right'}`}
            style={{ backgroundColor: item.color }}></div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Course;