import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo/logo-white-5.png';

const FooterTwo = (props) => {
  const { footerClass, footerLogo, copyrightTextClass } = props;

  return (
    <footer>
      <div
        className={
          footerClass
            ? footerClass
            : "it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-2 black-bg pb-70"
        }
      >
        <div className="it-footer-wrap pt-120">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-50 wow animate__fadeInUp mr-30"
                data-wow-duration=".9s"
                data-wow-delay=".3s"
              >
                <div className="it-footer-widget footer-col-5">
                  <div className="it-footer-logo pb-25">
                    <Link to="/">
                      <img
                        src={footerLogo ? footerLogo : Logo}
                        alt=""
                        style={{ width: "220px", borderRadius: "15px" }}
                      />
                    </Link>
                  </div>
                  <div className="it-footer-text pb-5">
                    <p
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        color: "white",
                        lineHeight: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      We Provide Complete Educational Services with over 75+
                      years of experience in the books trade and publishing.
                    </p>
                  </div>
                  <h4 className="highlight-title green">
                    <span>Follow us:</span>
                  </h4>
                  <div className="it-footer-social">
                    <a href="https://www.facebook.com/centralbooks.store">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/centralbooksonline/">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://in.pinterest.com/centralbooks">
                      <i className="fa-brands fa-pinterest-p"></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCcOXd8hwVR501Iv0YoOcD5Q">
                      <i className="fa-brands fa-youtube-square"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-2 col-md-6 col-sm-6 mb-50 wow animate__fadeInUp mr-30"
                data-wow-duration=".9s"
                data-wow-delay=".5s"
              >
                <div className="it-footer-widget footer-col-6">
                  <h4 className="it-footer-title highlight-title green">
                    <span>Useful Links:</span>
                  </h4>
                  <div className="it-footer-list">
                    <ul>
                      <li>
                        <a href="/about-us">
                          <i className="fa-regular fa-angle-right"></i>About Us
                        </a>
                      </li>
                      <li>
                        <a href="/services">
                          <i className="fa-regular fa-angle-right"></i>Our
                          Services
                        </a>
                      </li>
                      <li>
                        <a href="/ourteam">
                          <i className="fa-regular fa-angle-right"></i>
                          Our Team
                        </a>
                      </li>
                      <li>
                        <a href="/clients">
                          <i className="fa-regular fa-angle-right"></i>Our
                          Clients
                        </a>
                      </li>
                      <li>
                        <a href="/careers">
                          <i className="fa-regular fa-angle-right"></i>Careers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-2 col-md-6 col-sm-6 mb-50 wow animate__fadeInUp"
                data-wow-duration=".9s"
                data-wow-delay=".5s"
              >
                <div className="it-footer-widget footer-col-6">
                  <h4 className="it-footer-title highlight-title green">
                    <span>Useful Links:</span>
                  </h4>
                  <div className="it-footer-list">
                    <ul>
                      <li>
                        <a href="/ourbranches">
                          <i className="fa-regular fa-angle-right"></i>Our
                          Branches
                        </a>
                      </li>
                      <li>
                        <a href="/retailstores">
                          <i className="fa-regular fa-angle-right"></i>Retail Store
                          Location
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fa-regular fa-angle-right"></i>Contact
                          Us
                        </a>
                      </li>
                      <li>
                        <a href="https://app.keka.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fresponse_type%3Dcode%26client_id%3D987cc971-fc22-4454-99f9-16c078fa7ff6%26state%3DLmRIZHhQZHI3YVFHVTRlNVlsWUZ6WFViV0dKaFF3TjNJakRDY3lIZUg1Qi1-%26redirect_uri%3Dhttps%253A%252F%252Fcentralbooks.keka.com%26scope%3Dopenid%2520offline_access%2520kekahr.api%2520hiro.api%26code_challenge%3D39U2ciKPRg-WSWGIyAVwDT5bIAK_V3h4Lrl3LuIHAc8%26code_challenge_method%3DS256%26nonce%3DLmRIZHhQZHI3YVFHVTRlNVlsWUZ6WFViV0dKaFF3TjNJakRDY3lIZUg1Qi1-">
                          <i className="fa-regular fa-angle-right"></i>Employee
                          Login
                        </a>
                      </li>
                      <li>
                        <a href="/bookfair-events">
                          <i className="fa-regular fa-angle-right"></i>Book Fair
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-8 mb-50 wow animate__fadeInUp"
                data-wow-duration=".9s"
                data-wow-delay=".9s"
              >
                <div className="it-footer-widget footer-col-8">
                  <h4 className="it-footer-title highlight-title green">
                    <span>Contact Info:</span>
                  </h4>
                  <div
                    className="it-footer-text pb-5"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "left",
                      }}
                    >
                      <p
                        className="highlight-title green"
                        style={{ marginRight: "10px" }}
                      >
                        <span>
                          <i className="fas fa-phone-alt"></i>
                        </span>
                      </p>
                      <p style={{ margin: 0, textAlign: "left" }}>
                        040 2409 8282 / <br />
                        080 4718 3344
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "left",
                      }}
                    >
                      <p
                        className="highlight-title green"
                        style={{ marginRight: "10px" }}
                      >
                        <span>
                          <i className="fas fa-envelope"></i>
                        </span>
                      </p>
                      <p style={{ margin: 0, textAlign: "left" }}>
                        info@centralbooks.in
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "left",
                      }}
                    >
                      <p
                        className="highlight-title green"
                        style={{ marginRight: "10px" }}
                      >
                        <span>
                          <i className="fas fa-calendar-alt"></i>
                        </span>
                      </p>
                      <p style={{ margin: 0, textAlign: "left" }}>
                        Mon-Sat : 10am to 7pm, Sunday: Closed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="it-newsletter-left">
                      <h4 className="highlight-title green">
                        <span>Disclaimer:</span>
                      </h4>
                      <p
                      style={{
                        fontSize: "14px",
                        fontFamily:"arial",
                        fontStyle: "normal",
                        color: "white",
                        lineHeight: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      Dear Customer, Central Books accept the payments only from
                      its official website. We or our representatives will never
                      ask you for your online wallet payment (GPay, PhonePe,
                      Paytm) or card details. Please do not share these details
                      with anyone, as it could lead to fraud. Central Books will
                      not be responsible for any such payments/losses. Please
                      contact us if you get any calls/emails. Stay alert and
                      avoid fraud.
                    </p>
                    </div>
                  </div>
                  {/* <div className="col-xl-6 col-lg-6 col-md-6">
                  <h4 className="highlight-title green text-center">
                    <span>Subscribe Newsletter</span>
                  </h4>
                    <div className="it-newsletter-right p-relative text-end">
                      <input type="text" placeholder="Enter your email:" />
                      <button className="it-btn black-bg" type="submit">
                        <span>subscribe now</span>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="it-copyright-area it-copyright-height">
        <div className="container">
          <div className="row">
            <div
              className="col-12 wow animate__fadeInUp"
              data-wow-duration=".9s"
              data-wow-delay=".3s"
            >
              <div
                className={
                  copyrightTextClass
                    ? copyrightTextClass
                    : "highlight-title green text-center"
                }
              >
                <p
                  style={{
                    fontSize: "14px",
                    fontFamily: "arial",
                    fontStyle: "normal",
                    color: "white",
                    lineHeight: "20px",
                    paddingRight: "20px",
                  }}
                >
                  Copyright &copy; 2012-2025{" "}
                  <a href="/">
                    <span>CBS HUB Private Limited.</span>
                  </a>{" "}
                  - All Rights Reserved. &nbsp;&nbsp;
                  <a href="/privacypolicy" className="hover:underline">
                  <span>Privacy Policy</span>
                  </a>{" "}
                  &nbsp;|&nbsp;{" "}
                  <a href="/termsofservice" className="hover:underline">
                  <span>Terms of Service</span>
                  </a>{" "}
                  &nbsp;|&nbsp;{" "}
                  <a href="/cookiessettings" className="hover:underline">
                  <span> Cookies Settings</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterTwo;
