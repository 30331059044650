import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';

import Logo from '../../assets/img/logo/logo-white-2.png';
import aboutImg1 from '../../assets/img/about/credo.jpg';
import aboutImg2 from '../../assets/img/about/credo2.png';


const Credo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <main>
    <HeaderFour />
    <Breadcrumb title="Credo " subTitle="Credo" />
    <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
    <div className="container">
      <div className="row">
        <div
          className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
          data-wow-duration=".9s"
          data-wow-delay=".3s"
        >
          <img src={aboutImg1} alt="" />
          <img src={aboutImg2} alt="" />
        </div>
      </div>
    </div>
  </div>
    <FooterTwo
      footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
      footerLogo={Logo}
      btnClass="it-btn-white sky-bg"
      copyrightTextClass="it-copyright-text inner-style text-center"
    />
  </main>
  );
};
export default Credo;
