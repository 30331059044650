import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import homeImg1 from '../../assets/img/menu/home-1.jpg';
import homeImg2 from '../../assets/img/menu/home-2.jpg';
import homeImg3 from '../../assets/img/menu/home-3.jpg';
import homeImg4 from '../../assets/img/menu/home-4.jpg';
import homeImg5 from '../../assets/img/menu/home-5.jpg';

const MenuItems = (props) => {
  const { mobileMenu } = props;
  const [home, setHome] = useState(false);
  const [course, setCourse] = useState(false);
  const [page, setPage] = useState(false);
  const [blog, setBlog] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === 'home') {
      setHome(!home);
      setCourse(false);
      setPage(false);
      setBlog(false);
    } else if (menu === 'course') {
      setHome(false);
      setCourse(!course);
      setPage(false);
      setBlog(false);
    } else if (menu === 'page') {
      setHome(false);
      setCourse(false);
      setPage(!page);
      setBlog(false);
    } else if (menu === 'blog') {
      setHome(false);
      setCourse(false);
      setPage(false);
      setBlog(!blog);
    }
  };

  const handleClick = (e) => {
    if (mobileMenu) {
      e.preventDefault();
    }
  };

  return (
    <ul>
      <li>
        <Link to="/">
          <span>Home</span>
        </Link>
      </li>
      <li className="has-dropdown">
        <Link
          to="/about-us"
          className={course ? 'expanded' : ''}
          onClick={handleClick}
        >
          <span>About Us</span>
          <button
            className={`${
              course
                ? 'dropdown-toggle-btn dropdown-opened'
                : 'dropdown-toggle-btn'
            } d-xl-none`}
            onClick={() => {
              openMobileMenu('course');
            }}
          >
            <i className="fal fa-angle-right"></i>
          </button>
        </Link>
        <ul
          className={
            course ? 'it-submenu submenu d-block' : 'it-submenu submenu'
          }
        >
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/ourteam">Meet Our Team</Link>
          </li>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
        </ul>
      </li>
      <li className="has-dropdown p-static">
        <Link to="/services" className={page ? 'expanded' : ''} onClick={handleClick}>
          <span>Services</span>
          <button
            className={`${
              page
                ? 'dropdown-toggle-btn dropdown-opened'
                : 'dropdown-toggle-btn'
            } d-xl-none`}
            onClick={() => {
              openMobileMenu('page');
            }}
          >
            <i className="fal fa-angle-right"></i>
          </button>
        </Link>
        <div
          className={
            page
              ? 'it-submenu submenu it-mega-menu d-block'
              : 'it-submenu submenu it-mega-menu'
          }
        >
          <div className="it-mega-menu-wrap">
            <div className="row">
              <div className="col-xl-3">
                <ul className="it-megamenu-space-1">
                  <li>
                  <span className="ed-section-subtitle">Bulk Supplies & Kits</span>
                  </li>
                  <li>
                    <Link to="/bulksupplieskits">Bulk Supplies & Kits</Link>
                  </li>
                  <li>
                    <Link to="/ecommerceservices">Ecommerce Online Services</Link>
                  </li>
                  <li>
                    <Link to="/tuckshopsales">Tuck Shop Sales</Link>
                  </li>
                </ul>
              </div>
              <div className="col-xl-4">
                <ul className="it-megamenu-space-2">
                  <li>
                  <span className="ed-section-subtitle">Exclusive Services</span>
                  </li>
                  <li>
                    <Link to="/librarysolutions">Library Solutions</Link>
                  </li>
                  <li>
                    <Link to="/certifiedexaminer">The Certified Examiner</Link>
                  </li>
                  <li>
                    <Link to="/customizationdesign">Customization & Design</Link>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3">
                <ul className="it-megamenu-space-3">
                  <li>
                  <span className="ed-section-subtitle">Stationery Supplies</span>
                  </li>
                  <li>
                    <Link to="/stationerysales">Stationery Supplies</Link>
                  </li>
                </ul>
              </div>
              <div className="col-xl-2">
                <ul className="it-megamenu-space-4">
                  <li>
                  <span className="ed-section-subtitle">Notebooks</span>
                  </li>
                  <li>
                    <Link to="/notebookmanufacturing">Notebook Manufacturing</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <Link to="/credo">
          <span>Credo</span>
        </Link>
      </li>
      <li>
        <Link to="/it-and-infra">
          <span>IT & Infra</span>
        </Link>
      </li>
      <li className="has-dropdown">
        <Link
          to="/clients"
          className={blog ? 'expanded' : ''}
          onClick={handleClick}
        >
          <span>More..</span>
          <button
            className={`${
              blog
                ? 'dropdown-toggle-btn dropdown-opened'
                : 'dropdown-toggle-btn'
            } d-xl-none`}
            onClick={() => {
              openMobileMenu('blog');
            }}
          >
            <i className="fal fa-angle-right"></i>
          </button>
        </Link>
        <ul
          className={blog ? 'it-submenu submenu d-block' : 'it-submenu submenu'}
        >
          <li>
            <Link to="/clients">Clients</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/bookfair-events">Book Fair & Events</Link>
          </li>
        </ul>
      </li>
    </ul>
  );
};
export default MenuItems;
