/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import FooterTwo from '../../components/Footer/FooterTwo';
import HeaderFour from '../../components/Header/HeaderFour';

import Logo from '../../assets/img/logo/logo-white-5.png';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import Breadcrumb from '../../components/Breadcrumb';
import RightArrow from '../../components/SVG';
import SingleProgress from '../../components/Progress';
import SectionTitle from '../../components/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';
import NextArrow from '../../components/SVG/NextArrow';
import PrevArrow from '../../components/SVG/PrevArrow';

import teamMainImg from '../../assets/img/team/team-inner.jpg';
import teamImg1 from '../../assets/img/team/team-4-1.jpg';
import teamImg2 from '../../assets/img/team/team-4-2.jpg';
import teamImg3 from '../../assets/img/team/team-4-3.jpg';
import teamImg4 from '../../assets/img/team/team-4-4.jpg';
import teamImg5 from '../../assets/img/team/team-4-5.jpg';
import teamImg6 from '../../assets/img/team/team-4-6.jpg';

const Careers = () => {
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);
  const sliderOption = {
    speed: 1500,
    loop: true,
    slidesPerView: '4',
    spaceBetween: 50,
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      1400: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      0: {
        slidesPerView: 1,
      },
    },
    navigation: {
      prevEl: '.slider-prev',
      nextEl: '.slider-next',
    },
  };
  useEffect(() => {
      window.$('select').niceSelect();
    }, []);
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Careers" subTitle="Careers" />
      <div className="it-teacher-details-area pt-10 pb-10">
        <div className="container">
          <div className="it-teacher-details-wrap">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="it-teacher-details-right">
                  <div className="it-teacher-details-right-title-box">
                    <h4>Working At CENTRAL BOOKS</h4>
                    <span>Come and Join Us!</span>
                    <p>
                    A culture of innovation at the heart
                    of satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ed-team-area grey-bg-5 p-relative inner-style fix z-index pt-110 pb-120">
        <div className="container">
          <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12">
                <SectionTitle
                  itemClass="it-team-title-box text-center"
                  subTitleClass="ed-section-subtitle"
                  subTitle="Submit Your Resume"
                  titleClass="ed-section-title"
                  title="Ask Us for Current Openings & Submit Your Resume here"
                />
              </div>
              <div className="it-student-regiform">
                  <h4 className="it-student-regiform-title">
                  Submit Your Resume here
                  </h4>
                  <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/nW1Z0P?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="669"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Careers"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
      />
    </main>
  );
};
export default Careers;
