/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import Logo from '../../assets/img/logo/logo-white-2.png';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/ecommerce.jpg';
import titleImg from '../../assets/img/category/title.svg';
import FaqOne from '../../components/Faq';

import faqMainImg from '../../assets/img/about/ecommercetwo.jpg';
import emailjs from '@emailjs/browser';
import shapeImg from '../../assets/img/contact/shape-2-1.png';


const CertifiedExaminer = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const items = [
    {
      icon: 'flaticon-study',
      title: 'Convenience Beyond Compare',
      description:
        'Say goodbye to long queues and last-minute academic shopping! Our platform allows parents to browse, select, and purchase books and kits anytime, anywhere—all from the comfort of their homes.',
    },
    {
      icon: 'flaticon-exam',
      title: 'Extensive Product Range',
      description:
        'School Textbooks: For CBSE, ICSE, State Boards, and international syllabus.\n Competitive Exam Guides: Resources for career-defining tests. \n General Reading: Books to inspire and enrich. \n Daily Stationery Needs: Everything from pens to notebooks.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Multiple Delivery Options',
      description:
        'Home Delivery: Convenient delivery straight to your doorstep. School Delivery: Organized deliveries directly to schools for easy distribution.',
    },
    {
      icon: 'flaticon-pin',
      title: 'Custom-Tailored Solutions',
      description:
        'Our platform ensures every order aligns perfectly with the school curriculum, making it easy for parents to purchase the exact books and supplies their children need.',
    },
    {
      icon: 'flaticon-pin',
      title: 'Reliable and Secure Transactions',
      description:
        'We value your trust. With robust payment options and transparent pricing, we ensure every transaction is safe, simple, and hassle-free.',
    },
  ];
  const faqitems = [
    {
      id: 'a',
      btnText: 'Your Academic Book Shopping Online with Convenience',
      description:
        'With CentralBooksOnline.com, we have transformed how parents, students, and schools access academic materials. Our user-friendly website, intuitive navigation, and detailed product descriptions ensure a seamless shopping experience. By collaborating closely with schools, we guarantee that every student has access to the online platform they need to thrive.',
      
    },
    {
      id: 'b',
      btnText: 'We Prioritize Speed, Reliability, and Customer Satisfaction',
      description:
        'At Central Books, we prioritize speed, reliability, and customer satisfaction. With 85% of orders delivered within just 3 days, we ensure your supplies arrive quickly. Our order tracking dashboard allows schools and institutions to easily track and manage their orders, with over 100,000 orders processed in just 3 months. Plus, our dedicated customer support team is always ready to assist, making your experience seamless and stress-free.',
      
    },
  ];
  
 const formRef = useRef();

  return (
    <main>
      <HeaderFour />
      <Breadcrumb
        title="Ecommerce Online Services "
        subTitle="Ecommerce Online Services"
      />
      <div
        id="it-about"
        className="it-about-3-area it-about-4-style p-relative grey-bg pt-120 pb-120"
      >
        <div className="ed-about-3-shape-2">
          <img src={shapeImg1} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5">
              <div className="ed-about-3-thumb-wrap p-relative">
                <div className="ed-about-3-shape-1 d-none d-md-block">
                  <img src={shapeImg2} alt="" />
                </div>
                <div className="ed-about-3-thumb">
                  <img src={aboutImg1} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7">
              <SectionTitle
                itemClass="it-about-3-title-box"
                subTitleClass="it-section-subtitle-5 orange"
                subTitle="Ecommerce Online Services"
                titleClass="it-section-title-3 pb-30"
                title="Academic Books for Schools and Parents"
                titleImage={titleImg}
                description="In today’s fast-paced world, CentralBooksOnline.com brings a revolutionary solution to academic book shopping by combining convenience, reliability, and accessibility. With technology at the core of our services, we ensure that parents, students, and schools have a stress-free experience in procuring educational materials."
              />

              <div className="it-about-3-mv-box">
                <div className="row">
                  <h4>Your Trusted Online Academic Partner</h4>
                  <p>
                    CentralBooksOnline.com is more than just an online
                    bookstore, it's a one-stop education portal designed to
                    cater to every academic need. From school textbooks and
                    competitive exam guides to general reading books and
                    stationery, our platform ensures, you have everything you
                    need at your fingertips.
                  </p>
                  <h4>
                    Designed Online Experience for Parents, Loved by Schools
                  </h4>
                  <p>
                    Empowering parents is at the heart of what we do. Parents
                    can now place orders for their children’s school books and
                    stationery directly online. Many schools in Hyderabad,
                    Mumbai, and other cities encourage parents to utilize this
                    platform for purchasing academic supplies that include
                    textbooks, notebooks, stationery, and other school-preferred
                    materials. Whether delivered straight to their home or
                    collected at school, parents can count on timely, accurate,
                    and reliable service.
                  </p>
                  <h4>Why Choose CentralBooksOnline.com?</h4>
                  {items.map((items, index) => (
                    <div key={index} className="col-xl-12">
                      <div className="it-about-4-list-wrap d-flex align-items-start">
                        <div className="it-about-4-list-icon">
                          <span>
                            <i className={items.icon}></i>
                          </span>
                        </div>
                        <div>
                          <span className="it-about-3-mv-title">
                            {items.title}
                          </span>
                          <p>
                            {items.description.split("\n").map((line, idx) => (
                              <React.Fragment key={idx}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="it-faq" className="it-faq-area p-relative pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
              data-wow-duration=".9s"
              data-wow-delay=".5s"
            >
              <div className="it-faq-wrap">
                <img src={faqMainImg} alt="" />
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 wow animate__fadeInRight"
              data-wow-duration=".9s"
              data-wow-delay=".7s"
            >
              <div className="it-faq-wrap">
                <SectionTitle
                  itemClass="it-faq-title-box mb-20"
                  subTitleClass="ed-section-subtitle"
                  subTitle="Benefits"
                  titleClass="it-section-title-5"
                  title="Simplifying Academic Shopping"
                />

                <FaqOne items={faqitems} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <SectionTitle
                itemClass="it-course-title-box mb-60 text-center"
                subTitleClass="it-section-subtitle-2 white-bg"
                subTitle="Join us"
                titleClass="ed-section-title"
                title="Join the Future of Academic Shopping"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
              data-wow-duration=".9s"
              data-wow-delay=".3s"
            >
              <p align="justify">
                At Central Books, we are committed to making education simpler,
                smarter, and more accessible. Whether you need textbooks for the
                academic year, materials for competitive exams, or stationery
                for daily use, CentralBooksOnline.com is your trusted partner in
                education.
                <br />
                <br />
                Visit{" "}
                <a href="https://CentralBooksOnline.com">
                  www.CentralBooksOnline.com
                </a>{" "}
                today and let us take care of your academic needs—delivering
                quality and convenience right to your doorstep or school!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-120 pb-120">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-7">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">
                      Get in Touch with us!
                    </h4>
                    <p>
                      Contact us today to learn more about our Ecommerce Online
                      Services <br />
                      Please let us know your requirements, we're Happy to
                      Assist you.
                    </p>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className="it-section-subtitle-5 orange">
                              Head Office & Sales Office:
                            </span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Ww56NKUkwpte2kwr8"
                              rel="noreferrer"
                            >
                              6-3-553, C1, 2nd Floor, Quena Square,
                              <br />
                              Erramanzil, Somajiguda, Hyderabad-500 082.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">
                              Mon - Sat: 10.00am to 7.00pm
                            </a>
                            <span>Sunday: Holiday</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:08047183344">080 4718 3344</a>
                            <a href="mailto: info@centralbooks.in">
                              info@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/wvKzK0?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Ecommerce Online Services"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};
export default CertifiedExaminer;