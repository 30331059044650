import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import Breadcrumb from '../../components/Breadcrumb';

import serviceImg from '../../assets/img/service/ECommerce-Central-Books.jpg';
import serviceImg2 from '../../assets/img/service/ERP-Centralbooks.jpg';
import serviceImg3 from '../../assets/img/about/customerSupport.jpg';
import Logo from '../../assets/img/logo/logo-white-2.png';


const ItInfra = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="IT and Infra" subTitle="ITandInfra" />

      <div className="it-sv-details-area pt-60 pb-30">
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="PO_fBTkoznc"
          onClose={() => openModal()}
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="it-sv-details-top-wrap mb-20">
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="it-sv-details-top-thumb">
                      <img src={serviceImg2} alt="" />
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="it-sv-details-top-content">
                      <h4 className="it-sv-details-title">Microsoft Dynamics ERP</h4>
                      <p>
                      We are one of the most modern businesses in our sector for use of technology. Our core business information is entirely computerized via our Microsoft Dynamics ERP system hosted on Azure cloud.
                      </p>
                    </div>
                    <div className="it-feature-button">
                                    <Link className="ed-btn-radius" to="#">
                                    Know More..
                                    </Link>
                                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-sv-details-area pt-30 pb-30">
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="PO_fBTkoznc"
          onClose={() => openModal()}
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="it-sv-details-top-wrap mb-20">
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="it-sv-details-top-thumb">
                      <img src={serviceImg} alt="" />
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="it-sv-details-top-content">
                      <h4 className="it-sv-details-title">ECOMMERCE</h4>
                      <p>
                      We have a Magento-based e-commerce system which allows parents of students to place orders for books etc, and handles several thousand orders a day during peak season.<br/>
www.CentralBooksOnline.com
                      </p>
                    </div>
                    <div className="it-feature-button">
                                    <Link className="ed-btn-radius" to="/ecommerceservices">
                                    Know More..
                                    </Link>
                                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-sv-details-area pt-30 pb-30">
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="PO_fBTkoznc"
          onClose={() => openModal()}
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="it-sv-details-top-wrap mb-20">
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="it-sv-details-top-thumb">
                      <img src={serviceImg3} alt="" />
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="it-sv-details-top-content">
                      <h4 className="it-sv-details-title">In-house Customer Support</h4>
                      <p>
                      We have built a software inhouse which will works an self care option for our customers. Here customers will be able to access order status, invoices, statements, payment details etc.,
                      </p>
                    </div>
                    <div className="it-feature-button">
                                    <Link className="ed-btn-radius" to="#">
                                    Know More..
                                    </Link>
                                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};
export default ItInfra;
