/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import Breadcrumb from '../../components/Breadcrumb';
import ServiceSidebar from '../../components/ServiceSidebar';

import serviceImg from '../../assets/img/service/sv-1.jpg';
import serviceImg2 from '../../assets/img/service/sv-2.jpg';
import Logo from '../../assets/img/logo/logo-white-2.png';
import AboutHomeThree from '../home-3/AboutSection';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/notebook.jpg';
import aboutImg2 from '../../assets/img/about/thumb-4-2.jpg';
import titleImg from '../../assets/img/category/title.svg';
import FaqOne from '../../components/Faq';

import faqMainImg from '../../assets/img/about/notebooktwo.jpg';
import shapeImg from '../../assets/img/contact/shape-2-1.png';


const CertifiedExaminer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);
  const items = [
    {
      icon: 'flaticon-study',
      title: 'School Notebooks',
      description:
        'Perfectly aligned with the curriculum and designed for students of all ages. Available in different sizes, designs, and paper types to cater to individual preferences.',
    },
    {
      icon: 'flaticon-exam',
      title: 'Custom-Branded Notebooks',
      description:
        'Tailored to your school or company’s branding, these notebooks are ideal for promoting identity and creating a professional appearance.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Specialized Notebooks',
      description:
        'From spiral-bound notebooks for creative professionals to composition books for academic use, we create custom solutions for any occasion.',
    },
    {
      icon: 'flaticon-pin',
      title: 'Eco-Friendly Notebooks',
      description:
        'With sustainability in mind, we offer notebooks made from recycled materials, ensuring that your stationery needs are met responsibly.',
    },
  ];
  const faqitems = [
    {
      id: 'a',
      btnText: 'High-Quality Materials',
      description:
        'We use premium-quality paper and binding materials to ensure durability and smooth writing experience. Our notebooks are designed to withstand the daily demands of students and professionals alike.',
      
    },
    {
      id: 'b',
      btnText: 'Customization at Its Best',
      description:
        'From cover designs to page layouts, we offer full customization options to make sure the notebooks match your brand’s personality and your specific requirements.',
      
    },
    {
      id: 'c',
      btnText: 'Cost-Effective Bulk Orders',
      description:
        'Whether you need hundreds or thousands of notebooks, we provide cost-effective bulk manufacturing solutions, ensuring that you get the best price without compromising on quality.',
      
    },
    {
      id: 'd',
      btnText: 'Timely Delivery',
      description:
        'We understand the importance of timelines, especially for schools and businesses. Our streamlined production process ensures that all orders are completed on time, every time.',
      
    },
  ];
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef();
  const toggleModal = () => setIsModalOpen(!isModalOpen);
 
  return (
    <main>
    <HeaderFour />
    <Breadcrumb title="Notebook Manufacturing " subTitle="Notebook Manufacturing" />
    <div
    id="it-about"
    className="it-about-3-area it-about-4-style p-relative grey-bg pt-120 pb-120"
  >
    <div className="ed-about-3-shape-2">
      <img src={shapeImg1} alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-5">
          <div className="ed-about-3-thumb-wrap p-relative">
            <div className="ed-about-3-shape-1 d-none d-md-block">
              <img src={shapeImg2} alt="" />
            </div>
            <div className="ed-about-3-thumb">
              <img src={aboutImg1} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-lg-7">
          <SectionTitle
            itemClass="it-about-3-title-box"
            subTitleClass="it-section-subtitle-5 orange"
            subTitle="Notebook Manufacturing"
            titleClass="it-section-title-3 pb-30"
            title="Custom Notebooks for Schools"
            titleImage={titleImg}
            description="At Central Books, we take pride in offering high-quality Notebook Manufacturing Services tailored to meet the needs of schools, businesses, and individuals. Whether you're a school looking for branded notebooks for your students, a company having customized notebooks for your employees, or a retailer requiring bulk supplies, we have got you covered."
          />

          <div className="it-about-3-mv-box">
            <div className="row">
            <h4>Our Notebook Manufacturing Services</h4>
<p>We specialize in producing a wide variety of notebooks to suit every requirement:</p>

<div className="ed-header-button text-center d-none d-lg-block">
              <Link className="ed-btn-theme theme-2" data-tally-open="3XzNpd" data-tally-layout="modal" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">
              Download Brochure
                <i className="fa-solid fa-arrow-down"></i>
              </Link>
            </div>
            <p>&nbsp;</p>
      <p>&nbsp;</p>
              {items.map((items, index) => (
                <div key={index} className="col-xl-12">
                  <div className="it-about-4-list-wrap d-flex align-items-start">
                    <div className="it-about-4-list-icon">
                      <span>
                        <i className={items.icon}></i>
                      </span>
                    </div>
                    <div>
                      <span className="it-about-3-mv-title">
                        {items.title}
                      </span>
                      <p>{items.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="it-faq" className="it-faq-area p-relative pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div
          className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
          data-wow-duration=".9s"
          data-wow-delay=".5s"
        >
          <div className="it-faq-wrap">
            <img src={faqMainImg} alt="" />
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-6 wow animate__fadeInRight"
          data-wow-duration=".9s"
          data-wow-delay=".7s"
        >
          <div className="it-faq-wrap">
            <SectionTitle
              itemClass="it-faq-title-box mb-20"
              subTitleClass="ed-section-subtitle"
              subTitle="Benefits"
              titleClass="it-section-title-5"
              title="Why Choose Our Notebook Manufacturing Services?"
            />

            <FaqOne items={faqitems} />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6">
          <SectionTitle
            itemClass="it-course-title-box mb-60 text-center"
            subTitleClass="it-section-subtitle-2 white-bg"
            subTitle="Join us"
            titleClass="ed-section-title"
            title="Supporting Schools, Businesses, and Beyond"
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
          data-wow-duration=".9s"
          data-wow-delay=".3s"
        >
          <p align="justify">Our Notebook Manufacturing Services are designed to meet the diverse needs of our clients, from educational institutions to corporate clients and individual retailers. With our extensive experience and commitment to quality, we are the go-to choice for custom notebook solutions.<br/><br/>
          At Central Books, we do not just make notebooks—we craft personalized, high-quality products that cater to your specific needs. Whether for education, business, or personal use, we are here to deliver the perfect notebooks that help you stay organized and productive.<br/><br/>
          Contact us today to learn more about our Notebook Manufacturing Services and how we can create the perfect notebook for you!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="it-contact__area pt-120 pb-120">
      <div className="container">
        <div className="it-contact__wrap fix z-index-3 p-relative">
          <div className="it-contact__shape-1 d-none d-xl-block">
            <img src={shapeImg} alt="" />
          </div>
          <div className="row align-items-end">
            <div className="col-xl-7">
              <div className="it-contact__right-box">
                <div className="it-contact__section-box pb-20">
                  <h4 className="it-contact__title pb-15">Get in Touch with us!</h4>
                  <p>
                  Contact us today to learn more about our Notebook Manufacturing Services.{' '}
                  <br/>Please let us know your requirements, we're Happy to Assist you.
                  </p>
                </div>
                <div className="it-contact__content mb-55">
                  <ul>
                  <li>
                      <div className="it-contact__list d-flex align-items-start">
                        <div className="it-contact__icon">
                          <span>
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                        </div>
                        <div className="it-contact__text">
                            <span>Our Address</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Tqwzy8MyXJb9SErQ9"
                            >
                               #3-6-418, Street No. 3, Himayath Nagar, <br />
                               Hyderabad - 500 029.
                            </a>
                          </div>
                      </div>
                    </li>
                    <li>
                      <div className="it-contact__list d-flex align-items-start">
                        <div className="it-contact__icon">
                          <span>
                            <i className="fa-solid fa-clock"></i>
                          </span>
                        </div>
                        <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#">Monday to Saturday: 10.00am to 7.00pm</a>
                            <span>[Sunday Holiday]</span>
                          </div>
                      </div>
                    </li>
                    <li>
                      <div className="it-contact__list d-flex align-items-start">
                        <div className="it-contact__icon">
                          <span>
                            <i className="fa-solid fa-phone phone"></i>
                          </span>
                        </div>
                        <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:08047183344">080 4718 3344</a>
                            <a href="mailto: info@centralbooks.in">
                              info@centralbooks.in
                            </a>
                          </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/mJMooo?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Notebook Manufacturing"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <FooterTwo
      footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
      footerLogo={Logo}
      btnClass="it-btn-white sky-bg"
      copyrightTextClass="it-copyright-text inner-style text-center"
    />
  </main>
  );
};
export default CertifiedExaminer;