/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Logo from '../../assets/img/logo/logo-white-2.png';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/img109.jpg';
import aboutImg2 from '../../assets/img/about/img88.jpg';
import shapeImg from '../../assets/img/contact/shape-2-1.png';

const LibrarySolutions = () => {
  const [activeTab, setActiveTab] = useState("CBSE");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef();
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);
  
  const cbseContent = (
    <div>
      <p>
        At Central Books, we believe in the power of reading to inspire,
        educate, and empower students. Our Library Solutions service offers an
        exceptional collection of children’s books for classes 1 to 12,
        meticulously selected and curated by experts. These books are not just
        suitable for academic needs—they are crafted to spark curiosity,
        critical thinking, and a lifelong love of learning.
      </p>
      <h4>Curated Collections for Every Age and Grade</h4>
      <p>
        Our Library Solutions service is designed to align with the academic
        criteria of both CBSE and State Board curriculums. We work closely with
        renowned agencies such as the American Library Association (ALA), AASL,
        the International Reading Association, and PISA to bring you an outstanding
        selection of award-winning and medal-winning titles. Each book is
        handpicked to meet global educational standards and to foster
        well-rounded development for students.
      </p>
      <p>
        Our collection spans across various genres and is categorized according
        to the latest CBSE norms, ensuring that schools can easily find
        materials suited for all educational boards.
      </p>
      <div className="ed-header-button text-center d-none d-lg-block">
              <Link className="ed-btn-theme theme-2" data-tally-open="3jvE79" data-tally-layout="modal" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">
              Download CBSE Brochure
                <i className="fa-solid fa-arrow-down"></i>
              </Link>
            </div>
    </div>
  );

  const ibContent = (
    <div>
      <h4>Library Solutions for IB Schools</h4>
      <p>
        We are excited to introduce Library Solutions, a dedicated vertical of
        CBS Hub, which has been a trusted partner to schools across India for
        over 75 years, providing educational resources to support the academic
        needs of students. With our headquarters in Hyderabad, and branch
        offices in Mumbai.
      </p>
      <p>
        Library Solutions offers a carefully curated catalog designed
        specifically for IB schools, with resources that align with the unique
        demands of the International Baccalaureate (IB) curriculum. Our catalog
        supports IB schools in developing well-rounded, globally aware students
        by offering books and resources that complement the inquiry-based,
        interdisciplinary learning approach that defines the IB framework.
      </p>
      <p>
        Organized by subject area, grade level, and themes that focus on
        critical thinking, cultural understanding, and holistic development,
        our selection is tailored to meet the diverse educational needs of IB
        students. With a special focus on age appropriateness and academic
        rigor, our resources ensure that students are equipped with the
        materials they need for success.
      </p>
      <div className="ed-header-button text-center d-none d-lg-block">
              <Link className="ed-btn-theme theme-2" data-tally-open="3lv4E5" data-tally-layout="modal" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">
              Download IB Brochure
                <i className="fa-solid fa-arrow-down"></i>
              </Link>
            </div>
    </div>
  );

  const items = [
    {
      icon: 'flaticon-study',
      title: 'Comprehensive & Expertly Curated Catalog',
      description:
        'We offer a diverse range of books for students of all grades, from young learners in primary classes to advanced readers in senior secondary levels. Each book is chosen for its educational value and its ability to engage students in different learning processes.',
    },
    {
      icon: 'flaticon-exam',
      title: 'Global Standards Compliance',
      description:
        'The books in our collection meet global educational standards, ensuring that your library is aligned with international assessments like PISA, Parag, and others. This provides students with the resources they need to succeed both locally and globally.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Support for All Educational Programs',
      description:
        'Whether your school follows CBSE, ICSE, or any other educational framework, our library solutions are designed to complement your curriculum. Empower your students with the resources that support rigorous academic programs and encourage independent learning.',
    },
    {
      icon: 'flaticon-pin',
      title: 'Fostering a Love for Reading',
      description:
        'Our curated collection is not just about textbooks and academic resources. It also includes stories, novels, and non-fiction that inspire students to read beyond the syllabus, sparking imagination and creativity.',
    },
    {
      icon: 'flaticon-pin',
      title: 'Investing in Educational Excellence',
      description:
        'Investing in a well-rounded library collection is an investment in your students future. Our Library Solutions empower schools to offer the best educational resources, helping students achieve academic excellence and nurturing a passion for reading.',
    },
  ];
  

  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Library Solutions " subTitle="Library Solutions" />
      <div
        id="it-about"
        className="it-about-3-area it-about-4-style p-relative grey-bg pt-40 pb-50"
      >
        <div className="container">
          <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12">
                <div className="ed-about-3-shape-2">
                  <img src={shapeImg1} alt="" />
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "100%" }}
                >
                  <img
                    src="/static/media/librarysolutions.2487eb1e64562aa3bfe4.png"
                    alt=""
                    width="320px"
                  />
                </div>
                <SectionTitle
                  itemClass="it-team-title-box text-center"
                  subTitleClass="ed-section-subtitle"
                  subTitle="Library Solutions"
                  titleClass="ed-section-title"
                  title="Elevating Education with Curated Collections"
                  description={
                    <>
                      We Curate PYP Series catalogs are curated by a team of experts with over 25 years of experience in IB schools.
                      <br />
                      We provide customized solutions in enhancing the library collection to meet your unique needs.
                    </>
                  }/>
                <div
                  className="tab-navigation"
                  style={{ marginBottom: "20px" }}
                >
                  <strong>Select the Board:&nbsp;&nbsp;</strong>
                  <button
                    onClick={() => setActiveTab("CBSE")}
                    className={
                      activeTab === "CBSE" ? "active-tab" : "tab-button"
                    }
                    style={{
                      padding: "10px 20px",
                      marginRight: "10px",
                      cursor: "pointer",
                      backgroundColor:
                        activeTab === "CBSE" ? "#ff9800" : "#f1f1f1",
                      color: activeTab === "CBSE" ? "#fff" : "#000",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    CBSE Board
                  </button>
                  <button
                    onClick={() => setActiveTab("IB")}
                    className={activeTab === "IB" ? "active-tab" : "tab-button"}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      backgroundColor:
                        activeTab === "IB" ? "#ff9800" : "#f1f1f1",
                      color: activeTab === "IB" ? "#fff" : "#000",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    IB Board
                  </button>
                </div>
                <div className="tab-content">
                  {activeTab === "CBSE" ? cbseContent : ibContent}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pt-90 pb-40">
            <div className="col-xl-5 col-lg-5">
              <div className="ed-about-3-thumb-wrap p-relative">
                <div className="ed-about-3-shape-1 d-none d-md-block">
                  <img src={shapeImg2} alt="" />
                </div>
                <div className="ed-about-3-thumb">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="ed-about-3-thumb-sm">
                  <img src={aboutImg2} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7">
              <div className="it-about-3-mv-box">
                <div className="row">
                  <h4>Why Choose Our Library Solutions?</h4>
                  {items.map((items, index) => (
                    <div key={index} className="col-xl-12">
                      <div className="it-about-4-list-wrap d-flex align-items-start">
                        <div className="it-about-4-list-icon">
                          <span>
                            <i className={items.icon}></i>
                          </span>
                        </div>
                        <div>
                          <span className="it-about-3-mv-title">
                            {items.title}
                          </span>
                          <p>{items.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <SectionTitle
                itemClass="it-course-title-box mb-60 text-center"
                subTitleClass="it-section-subtitle-2 white-bg"
                subTitle="Join us"
                titleClass="ed-section-title"
                title="Empower Your Schools Learning Environment"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
              data-wow-duration=".9s"
              data-wow-delay=".3s"
            >
              <p align="justify">
                At Central Books, we understand that a strong library is the
                cornerstone of any successful academic program. With our
                carefully selected and well-categorized library books, your
                school can provide students with the resources they need to
                succeed and grow.
                <br />
                <br />
                Choose Library Solutions powered by Central Books to elevate
                your school’s academic resources and create an environment where
                students are inspired to learn, explore, and dream.
                <br />
                <br />
                Contact us today to discover how we can transform your school’s
                library and support your students’ educational journey.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-120 pb-120">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-7">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">
                      Get in Touch with us!
                    </h4>
                    <p>
                      Contact us today to learn more about our Library Solutions{" "}
                      <br />
                      Please let us know your requirements, we're Happy to
                      Assist you.
                    </p>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Our Address</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Tqwzy8MyXJb9SErQ9"
                            >
                               #3-6-418, Street No. 3, Himayath Nagar, <br />
                               Hyderabad - 500 029.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#">Monday to Saturday: 10.00am to 7.00pm</a>
                            <span>[Sunday Holiday]</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:+918885711474">+91 8885711474</a>
                            <a href="mailto:info@centrallibrary.in">
                            info@centrallibrary.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/mOkEkR?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Library Solutions"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};
export default LibrarySolutions;