/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import Logo from '../../assets/img/logo/logo-white-2.png';
import shapeImg from '../../assets/img/contact/shape-2-1.png';


const OurBranches = () => {

  return (
    <main>
      <HeaderFour />
      <Breadcrumb
        title="Office locations & Warehouses"
        subTitle="Office locations & Warehouses"
      />

      <div className="it-contact__area pt-60 pb-30">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">
                      Head Office & Sales Office:
                    </h4>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Head Office & Sales Office:</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Ww56NKUkwpte2kwr8"
                              rel="noreferrer"
                            >
                              6-3-553, C1, 2nd Floor, Quena Square,
                              <br />
                              Erramanzil, Somajiguda, Hyderabad-500 082.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">
                              Mon - Sat: 10.00am to 7.00pm
                            </a>
                            <span>Sunday: Holiday</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:08047183344">080 4718 3344</a>
                            <a href="mailto: info@centralbooks.in">
                              info@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Get Direction</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Ww56NKUkwpte2kwr8"
                              rel="noreferrer"
                            >
                              Google Maps Direction Link
                            </a>
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.8794532029224!2d78.4536559738299!3d17.417571801937473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97126f595f45%3A0xbcc5539f7a3f5f58!2sCentral%20Books!5e0!3m2!1sen!2sin!4v1737115059291!5m2!1sen!2sin"
                      width="400"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-60 pb-30">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">Mumbai Office:</h4>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Mumbai Office:</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/bJPV7U9gLGuPRCeH8"
                              rel="noreferrer"
                            >
                              Corporate Arena, 3rd Floor, Office Number 302, DP
                              Piramal Road,
                              <br />
                              Goregaon West, Mumbai - 400 104.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">
                              Mon - Sat: 10.00am to 7.00pm
                            </a>
                            <span>Sunday: Holiday</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:8657537220">+91 8657537220</a>
                            <a href="mailto:mumbai@centralbooks.in">
                              mumbai@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Get Direction</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/bJPV7U9gLGuPRCeH8"
                              rel="noreferrer"
                            >
                              Google Maps Direction Link
                            </a>
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.6214955563046!2d72.84403646935871!3d19.16803870944372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b653787c4f33%3A0x7b42cbc4fe77ad90!2sCorporate%20Arena%2C%20Sitaram%20Patkar%20Rd%2C%20Piramal%20Nagar%2C%20Goregaon%20West%2C%20Mumbai%2C%20Maharashtra%20400104!5e0!3m2!1sen!2sin!4v1737115568778!5m2!1sen!2sin"
                      width="400"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-60 pb-30">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-12">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">Warehouses :</h4>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Warehouse - Mallapur Branch:</span>
                            <a
                              target="_blank"
                              href="#"
                              rel="noreferrer"
                            >
                              IDA Nacharam Rd Number 9, Industrial Development,{" "}
                              <br />
                              Mallapur, Hyderabad, Telangana, 500076.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Warehouse - Nacharam Branch:</span>
                            <a
                              target="_blank"
                              href="#"
                              rel="noreferrer"
                            >
                              Plot No 1/5B, Chilka Nagar Main Road, TSIIC
                              Industrial Development Area Near Chilkanagar X
                              Road, Uppal,
                              <br />
                              Nacharam, Hyderabad, Telangana 500076.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Warehouse - Mumbai:</span>
                            <a
                              target="_blank"
                              href="#"
                              rel="noreferrer"
                            >
                              Vrindavan Warehousing Complex, H/14-Gala no.
                              7,8,9, Sonale Village, Mumbai Nashik Highway,
                              Bhiwandi - 421302.
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};
export default OurBranches;