import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import SingleFeature from '../../components/Feature';
import SectionTitle from '../../components/SectionTitle';
import { Link } from 'react-router-dom';

const ServiceTwoMain = () => {
  
  return (
    <main>
      <Breadcrumb title="Services" subTitle="Services" />

      <div className="it-feature-3-area it-feature-3-bg inner-style pt-20 pb-30">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-8">
              <SectionTitle
                itemClass="it-feature-3-title-box text-center mb-60 section-title-fixed-width"
                subTitleClass="ed-section-subtitle"
                subTitle="Bulk Supplies & Kits"
                titleClass="it-section-title-3"
                title="Bulk Supplies & Kits"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <Link to="/bulksupplieskits">
              <SingleFeature 
              icon="flaticon-book" 
              title="Bulk Supplies & Kits"
              description="We supply packed book kits, which include textbooks, notebooks, and stationery— “All in one kit”."
              />
              </Link>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
            <Link to="/ecommerceservices">
              <SingleFeature 
              icon="flaticon-shopping-cart" 
              title="ECommerce Services"
              description="CBS caters to customers online needs by selling books and kits through its website."
              />
              </Link>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
            <Link to="/tuckshopsales">
              <SingleFeature 
              icon="flaticon-skill" 
              title="Tuck Shop Sales"
              description="We handhold our salespeople in the school stalls and maintain the selling process on behalf of the school."
              />
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-8">
              <SectionTitle
                itemClass="it-feature-3-title-box text-center mb-60 section-title-fixed-width"
                subTitleClass="ed-section-subtitle"
                subTitle="Exclusive Services"
                titleClass="it-section-title-3"
                title="Exclusive Services"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <Link to="/librarysolutions">
              <SingleFeature 
              icon="flaticon-teacher" 
              title="Library Solutions"
              description="Our library team curates a comprehensive collection of general books aligned with CBSE guidelines and other boards."
              />
              </Link>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
            <Link to="/certifiedexaminer">
              <SingleFeature 
              icon="flaticon-exam" 
              title="The Certified Examiner"
              description="As an authorized partner of Cambridge English Assessment, we conduct internationally recognized English proficiency tests."
              />
              </Link>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
            <Link to="/customizationdesign">
              <SingleFeature 
              icon="flaticon-ux-design" 
              title="Customization & Design"
              description="We offer Customized Textbooks, Notebooks to the schools for ease of teaching"
              />
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-8">
              <SectionTitle
                itemClass="it-feature-3-title-box text-center mb-60 section-title-fixed-width"
                subTitleClass="ed-section-subtitle"
                subTitle="Stationery Supplies"
                titleClass="it-section-title-3"
                title="Stationery Supplies"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <Link to="/stationerysales">
              <SingleFeature 
              icon="flaticon-art" 
              title="Stationery Supplies"
              description="We offer a wide range of stationery supplies to meet all your needs, from everyday essentials to specialized materials."
              />
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-8">
              <SectionTitle
                itemClass="it-feature-3-title-box text-center mb-60 section-title-fixed-width"
                subTitleClass="ed-section-subtitle"
                subTitle="Notebook Manufacturing"
                titleClass="it-section-title-3"
                title="Notebook Manufacturing"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <Link to="/notebookmanufacturing">
              <SingleFeature 
              icon="flaticon-learning" 
              title="Notebook Manufacturing"
              description="We manufacture notebooks for customers of all kinds."
              />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default ServiceTwoMain;
