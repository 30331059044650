import React from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import SectionTitle from '../../components/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';

import teamImg1 from '../../assets/img/team/team-4-1.jpg';
import teamImg2 from '../../assets/img/team/team-4-2.jpg';
import teamImg3 from '../../assets/img/team/team-4-3.jpg';
import teamImg4 from '../../assets/img/team/team-4-4.jpg';
import teamImg5 from '../../assets/img/team/team-4-5.jpg';
import teamImg6 from '../../assets/img/team/team-4-6.jpg';
import teamImg7 from '../../assets/img/team/team-4-7.jpg';
import teamImg8 from '../../assets/img/team/team-4-8.jpg';

const TermsofService = () => {
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Terms of Use" />
      <div className="ed-team-area p-relative inner-style fix z-index pt-110 pb-90">
        <div className="container">
          <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-10">
                <p>Effective Date: 9th January 2025.</p>
<p data-pm-slice="1 1 ">Welcome to <a>www.centralbooks.in</a> (hereinafter referred to as the &ldquo;Website&rdquo;). The Website is operated by Central Books (&ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;, or &ldquo;us&rdquo;). By accessing or using the Website, you agree to comply with and be bound by these Terms and Conditions (&ldquo;Terms&rdquo;). Please read these Terms carefully before using the Website. If you do not agree with any part of these Terms, please do not use our Website.</p>
<h3 data-pm-slice="1 1 "><strong>1. General Terms</strong></h3>
<p>1.1 By using this Website, you represent that you are at least 18 years of age or are using the Website under the supervision of a parent or guardian.</p>
<p>1.2 We reserve the right to refuse service, terminate accounts, or cancel orders at our discretion, including, without limitation, if we believe that customer conduct violates applicable laws or is harmful to our interests.</p>
<p>1.3 The content, products, and services provided on this Website are for personal, non-commercial use only. You may not copy, distribute, or sell any content without our prior written permission.</p>
<h3 data-pm-slice="1 1 "><strong>2. Account Registration</strong></h3>
<p>2.1 To access certain features of the Website, you may be required to register an account. You agree to provide accurate, complete, and up-to-date information during the registration process.</p>
<p>2.2 You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.</p>
<p>2.3 If you suspect any unauthorized use of your account, you must notify us immediately. We are not liable for any loss or damage arising from your failure to safeguard your account.</p>
<h3 data-pm-slice="1 1 "><strong>3. Orders and Payments</strong></h3>
<p>3.1 <strong>Placing Orders</strong>: All orders placed through the Website are subject to acceptance and availability. Once an order is placed, you will receive a confirmation email with the order details.</p>
<p>3.2 <strong>Pricing</strong>: All prices listed on the Website are in Indian Rupees (INR) and are inclusive of applicable taxes unless stated otherwise. We reserve the right to change prices at any time without prior notice.</p>
<p>3.3 <strong>Payment</strong>: We accept various payment methods, including credit/debit cards, UPI, net banking, and other digital payment options. By providing payment information, you represent and warrant that you are authorized to use the payment method.</p>
<p>3.4 <strong>Order Cancellation</strong>: You may cancel your order before it is dispatched. Once an order has been dispatched, it cannot be canceled. We reserve the right to cancel any order for reasons including but not limited to product unavailability or payment issues.</p>
<h3 data-pm-slice="1 1 "><strong>4. Shipping and Delivery</strong></h3>
<p>4.1 We strive to deliver your order within the estimated delivery time provided at the time of purchase. However, delays may occur due to unforeseen circumstances.</p>
<p>4.2 Delivery charges, if applicable, will be specified at the time of placing the order.</p>
<p>4.3 If the order is not delivered within the estimated time frame, you may contact our customer support for assistance.</p>
<p>4.4 Risk of loss and title for the products pass to you upon delivery.</p>
<h3 data-pm-slice="1 1 "><strong>5. Returns and Refunds</strong></h3>
<p>5.1 <strong>Eligibility</strong>: Products may be eligible for return if they are defective, damaged, or incorrect. To initiate a return, please contact our customer support within [Insert Number of Days] days of receiving the order.</p>
<p>5.2 <strong>Process</strong>: Upon approval of your return request, we will arrange for a pickup of the product. Once we receive the returned product and verify its condition, we will process a refund or replacement, as applicable.</p>
<p>5.3 <strong>Refund Method</strong>: Refunds will be processed through the original payment method within [Insert Number of Days] days of approval.</p>
<h3 data-pm-slice="1 1 "><strong>6. Intellectual Property</strong></h3>
<p>6.1 All content on the Website, including but not limited to text, graphics, logos, images, and software, is the property of Central Books or its content suppliers and is protected by applicable intellectual property laws.</p>
<p>6.2 You may not reproduce, duplicate, copy, sell, or exploit any portion of the Website without express written permission from us.</p>
<h3 data-pm-slice="1 1 "><strong>7. Limitation of Liability</strong></h3>
<p>7.1 To the maximum extent permitted by law, Central Books shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Website or inability to use the Website.</p>
<p>7.2 We make no warranties or representations about the accuracy or completeness of the content on the Website. The Website and all products and services are provided &ldquo;as is&rdquo; without any warranties of any kind.</p>
<h3 data-pm-slice="1 1 "><strong>8. Indemnification</strong></h3>
<p>You agree to indemnify and hold harmless Central Books, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses, including reasonable attorney&rsquo;s fees, arising out of your use of the Website or any violation of these Terms.</p>
<h3 data-pm-slice="1 1 "><strong>9. Governing Law and Dispute Resolution</strong></h3>
<p>9.1 These Terms shall be governed by and construed in accordance with the laws of India.</p>
<p>9.2 Any disputes arising out of or related to these Terms shall be subject to the exclusive jurisdiction of the courts located in [Insert City], India.</p>
<h3 data-pm-slice="1 1 "><strong>10. Changes to Terms and Conditions</strong></h3>
<p>We reserve the right to update or modify these Terms at any time without prior notice. The updated Terms will be posted on this page, and your continued use of the Website after any changes constitutes your acceptance of the revised Terms.</p>
<h3 data-pm-slice="1 1 "><strong>11. Contact Us</strong></h3>
<p>If you have any questions or concerns regarding these Terms and Conditions, please contact us at:</p>
<p><strong>Central Books</strong><br />Email: <a>info@centralbooks.in</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </main>
  );
};
export default TermsofService;