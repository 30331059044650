import React from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import SectionTitle from '../../components/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';

import teamImg1 from '../../assets/img/team/team-4-1.jpg';
import teamImg2 from '../../assets/img/team/team-4-2.jpg';
import teamImg3 from '../../assets/img/team/team-4-3.jpg';
import teamImg4 from '../../assets/img/team/team-4-4.jpg';
import teamImg5 from '../../assets/img/team/team-4-5.jpg';
import teamImg6 from '../../assets/img/team/team-4-6.jpg';
import teamImg7 from '../../assets/img/team/team-4-7.jpg';
import teamImg8 from '../../assets/img/team/team-4-8.jpg';

const OurTeam = () => {
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Privacy Policy" />
      <div className="ed-team-area p-relative inner-style fix z-index pt-110 pb-90">
        <div className="container">
          <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-10">
                <p>Effective Date: 9th January 2025.</p>
                <p>Central Books (“Company”, “we”, “our”, or “us”) values the trust of its users (“you”, “your”) and is committed to protecting your privacy while providing you with a safe and secure online experience. This Privacy Policy describes the types of personal information we collect, how we use and safeguard that information, and the rights you have regarding your personal data. Our practices comply with applicable Indian laws, including the Information Technology Act, 2000, and relevant data protection regulations.</p>
                <h3 data-pm-slice="1 1 "><strong>1. Information We Collect</strong></h3>
<p>We collect various types of information to enhance your experience on our website and provide you with relevant products and services.</p>
<h4><strong>1.1 Personal Information</strong></h4>
<p>Personal information refers to any information that identifies you as an individual. This information is collected when you:</p>
<ul data-spread="false">
<li>
<p>Create an account on our website</p>
</li>
<li>
<p>Place an order for products</p>
</li>
<li>
<p>Subscribe to our newsletter</p>
</li>
<li>
<p>Contact us for support</p>
</li>
</ul>
<p>The personal information we may collect includes:</p>
<ul data-spread="false">
<li>
<p>Full Name</p>
</li>
<li>
<p>Email Address</p>
</li>
<li>
<p>Phone Number</p>
</li>
<li>
<p>Shipping and Billing Address</p>
</li>
<li>
<p>Payment Details (e.g., credit/debit card information, UPI, etc.)</p>
</li>
<li>
<p>School Code (for academic book orders)</p>
</li>
</ul>
<h4><strong>1.2 Non-Personal Information</strong></h4>
<p>Non-personal information is data that cannot be used to directly identify an individual. This may include:</p>
<ul data-spread="false">
<li>
<p>Browser type and version</p>
</li>
<li>
<p>Operating system</p>
</li>
<li>
<p>IP address</p>
</li>
<li>
<p>Device information</p>
</li>
<li>
<p>Website usage data, such as pages visited, time spent on pages, and click patterns</p>
</li>
</ul>
<p>This information helps us improve our website performance, user experience, and service delivery.</p>
<h4><strong>1.3 Cookies and Tracking Technologies</strong></h4>
<p>We use cookies, web beacons, and similar technologies to collect non-personal information and enhance your browsing experience. Cookies are small files stored on your device that help us remember your preferences and provide personalized content.</p>
<p><strong>Types of cookies we use:</strong></p>
<ul data-spread="false">
<li>
<p><strong>Essential Cookies</strong>: Necessary for website functionality, such as remembering your login details.</p>
</li>
<li>
<p><strong>Performance Cookies</strong>: Help us understand how visitors use our website.</p>
</li>
<li>
<p><strong>Functional Cookies</strong>: Enhance the website&rsquo;s functionality by remembering your preferences.</p>
</li>
<li>
<p><strong>Targeting Cookies</strong>: Used to deliver personalized advertisements based on your browsing behavior.</p>
</li>
</ul>
<p>You can control the use of cookies through your browser settings. Disabling cookies may limit certain features of the website.</p>
<h3 data-pm-slice="1 3 "><strong>2. How We Use Your Information</strong></h3>
<p>We collect and use your information for the following purposes:</p>
<ol start="1" data-spread="false">
<li>
<p><strong>Order Processing and Fulfillment</strong>: To process your orders, arrange delivery, and provide order updates.</p>
</li>
<li>
<p><strong>Customer Support</strong>: To respond to your queries, provide support, and address your concerns.</p>
</li>
<li>
<p><strong>Personalization</strong>: To personalize your shopping experience by displaying content and recommendations based on your preferences.</p>
</li>
<li>
<p><strong>Marketing and Promotions</strong>: To send you promotional emails, offers, and newsletters. You can opt-out of marketing communications at any time.</p>
</li>
<li>
<p><strong>Site Improvement</strong>: To analyze usage patterns and improve the functionality and content of our website.</p>
</li>
<li>
<p><strong>Legal Compliance</strong>: To comply with applicable laws, regulations, and legal processes.</p>
</li>
</ol>
<h3 data-pm-slice="1 1 "><strong>3. Sharing of Information</strong></h3>
<p>We do not sell, rent, or trade your personal information to third parties. However, we may share your information in the following circumstances:</p>
<h4><strong>3.1 With Service Providers</strong></h4>
<p>We engage third-party service providers to perform functions on our behalf, such as:</p>
<ul data-spread="false">
<li>
<p>Payment processing</p>
</li>
<li>
<p>Order delivery and logistics</p>
</li>
<li>
<p>Website hosting and maintenance</p>
</li>
<li>
<p>Marketing and analytics services</p>
</li>
</ul>
<p>These service providers are contractually obligated to maintain the confidentiality and security of your information.</p>
<h4><strong>3.2 For Legal Purposes</strong></h4>
<p>We may disclose your information:</p>
<ul data-spread="false">
<li>
<p>When required by law, regulation, or legal process</p>
</li>
<li>
<p>To enforce our terms and conditions</p>
</li>
<li>
<p>To protect our rights, privacy, safety, or property, and that of our users and the public</p>
</li>
</ul>
<h3 data-pm-slice="1 1 "><strong>4. Data Security</strong></h3>
<p>We implement a variety of security measures to ensure the safety of your personal information. These include:</p>
<ul data-spread="false">
<li>
<p><strong>Encryption</strong>: Sensitive data, such as payment information, is encrypted during transmission using SSL (Secure Socket Layer) technology.</p>
</li>
<li>
<p><strong>Access Controls</strong>: Access to personal information is restricted to authorized personnel only.</p>
</li>
<li>
<p><strong>Regular Monitoring</strong>: We regularly monitor our systems for potential vulnerabilities and attacks.</p>
</li>
</ul>
<p>Despite our efforts, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>
<h3 data-pm-slice="1 1 "><strong>5. Your Rights</strong></h3>
<p>Under applicable Indian data protection laws, you have the following rights regarding your personal information:</p>
<ol start="1" data-spread="false">
<li>
<p><strong>Right to Access</strong>: You can request a copy of the personal information we hold about you.</p>
</li>
<li>
<p><strong>Right to Correction</strong>: You can request correction of any inaccurate or incomplete information.</p>
</li>
<li>
<p><strong>Right to Withdraw Consent</strong>: You can withdraw your consent for marketing communications at any time.</p>
</li>
<li>
<p><strong>Right to Data Deletion</strong>: You can request deletion of your personal data, subject to legal obligations.</p>
</li>
</ol>
<p>To exercise any of these rights, please contact us at <a>info@centralbooks.in</a>.</p>
<h3 data-pm-slice="1 1"><strong>6. Retention of Information</strong></h3>
<p>We retain your personal information for as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws. When your information is no longer needed, we will securely delete or anonymize it.</p>
<h3 data-pm-slice="1 1"><strong>7. Third-Party Links</strong></h3>
<p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of third-party websites before providing any personal information.</p>
<h3 data-pm-slice="1 1"><strong>8. Children&rsquo;s Privacy</strong></h3>
<p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will delete such information promptly.</p>
<h3 data-pm-slice="1 1"><strong>9. Changes to This Privacy Policy</strong></h3>
<p>We may update this Privacy Policy periodically to reflect changes in our practices, legal requirements, or other circumstances. When we make changes, we will revise the effective date at the top of the policy. We encourage you to review this policy regularly to stay informed about how we are protecting your information.</p>
<h3 data-pm-slice="1 1"><strong>10. Contact Us</strong></h3>
<p>If you have any questions or concerns regarding this Privacy Policy or the handling of your personal information, please contact us at:</p>
<p><strong>Central Books</strong><br />Email: <a>info@centralbooks.in</a><br />Contact Us page.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </main>
  );
};
export default OurTeam;