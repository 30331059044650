import React from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
// import SectionTitle from '../../components/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';

import teamImg1 from '../../assets/img/team/sudhirm.jpg';
import teamImg2 from '../../assets/img/team/Amrutha.jpg';
import teamImg3 from '../../assets/img/team/ramarao.jpg';
import teamImg4 from '../../assets/img/team/Vimal.jpg';
import teamImg5 from '../../assets/img/team/Ananda.jpg';
import teamImg6 from '../../assets/img/team/Ramakrishna.jpg';
import teamImg7 from '../../assets/img/team/Ravindranath.jpg';
import teamImg8 from '../../assets/img/team/Shyam.jpg';
import teamImg9 from '../../assets/img/team/Akash.jpg';
import teamImg10 from '../../assets/img/team/Karthik.jpg';
import teamImg11 from '../../assets/img/team/Narsingh.jpg';
import teamImg12 from '../../assets/img/team/Rajasekar.jpg';
import teamImg13 from '../../assets/img/team/Uday.jpg';

const OurTeam = () => {
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Our Team" />

      <div className="ed-team-area p-relative inner-style fix z-index pt-110 pb-90">
        <div className="container">
          {/* <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-6">
                <SectionTitle
                  itemClass="it-team-title-box text-center"
                  subTitleClass="ed-section-subtitle"
                  subTitle="Promoters"
                  titleClass="ed-section-title"
                  title="Promoters"
                />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg1}
                authorName="Sudhir Munagala"
                designation="Managing Director"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg2}
                authorName="Amrutha Munagala"
                designation="Managing COO & IT Head"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg3}
                authorName="Rama Rao Gunuganti"
                designation="Vice President - Sales | Telangana"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg4}
                authorName="Vimal Kohli"
                designation="Business Head – West Region"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg5}
                authorName="Ananda Krishnan"
                designation="Vice President Procurement"
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg6}
                authorName="Ramakrishna Ghanate"
                designation="Vice President – HR"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg7}
                authorName="Ravindranath Bandi"
                designation={
                  <>
                    Business Head<br />                    
                    Libraries & General Operations
                  </>
                }
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg8}
                authorName="Shyam Mechineni"
                designation={
                  <>
                    Business Head<br />                    
                    Certified Examiner
                  </>
                }
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg9}
                authorName="Akash Soni"
                designation={
                  <>
                    Head – Customer Support, Sales<br />                    
                    Order and Inside Sales
                  </>
                }
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg10}
                authorName="Karthik Reddy"
                designation={
                  <>
                    Senior Manager<br />                    
                    Accounts
                  </>
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg11}
                authorName="Narsingh Rao"
                designation={
                  <>
                    Manager<br />                    
                    B2B Warehouse
                  </>
                }
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg12}
                authorName="Rajasekar Mannelli"
                designation={
                  <>
                    Manager<br />                    
                    Online Warehouse
                  </>
                }
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-30">
              <SingleTeamThree
                teamImage={teamImg13}
                authorName="Uday Kumar"
                designation={
                  <>
                    Cluster Manager<br />                    
                    Retail
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </main>
  );
};
export default OurTeam;
