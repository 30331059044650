import React from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';

const CookiesSettings = () => {
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Cookies Settings" />
      <div className="ed-team-area p-relative inner-style fix z-index pt-110 pb-90">
        <div className="container">
          <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-10">
                <p>Effective Date: 9th January 2025.</p>
                <p data-pm-slice="1 1 ">At Central Books ("Company," "we," "our," or "us"), we use cookies and similar tracking technologies on our website (<a>www.centralbooks.in</a>) to enhance your browsing experience, deliver personalized content, and analyze site traffic. This Cookies Settings page outlines the types of cookies we use, their purposes, and how you can manage your cookie preferences.</p>
<h3 data-pm-slice="1 3 "><strong>1. What Are Cookies?</strong></h3>
<p>Cookies are small text files that are stored on your device (computer, smartphone, or tablet) when you visit a website. They help the website recognize your device, remember your preferences, and improve overall user experience.</p>
<p>Cookies can be classified into two categories:</p>
<ul data-spread="false">
<li>
<p><strong>Session Cookies</strong>: These cookies are temporary and are deleted once you close your browser.</p>
</li>
<li>
<p><strong>Persistent Cookies</strong>: These cookies remain on your device for a longer period or until you delete them.</p>
</li>
</ul>
<h3 data-pm-slice="1 1 "><strong>2. Types of Cookies We Use</strong></h3>
<h4><strong>2.1 Essential Cookies</strong></h4>
<p>These cookies are necessary for the functioning of the website and cannot be switched off in our system. They enable basic functions such as page navigation and access to secure areas of the website.</p>
<ul data-spread="false">
<li>
<p>Example: Remembering your login session and shopping cart details.</p>
</li>
</ul>
<h4><strong>2.2 Performance Cookies</strong></h4>
<p>Performance cookies help us understand how visitors interact with our website by collecting information about page visits, time spent on pages, and error messages. These cookies allow us to improve the performance and design of our website.</p>
<ul data-spread="false">
<li>
<p>Example: Google Analytics cookies.</p>
</li>
</ul>
<h4><strong>2.3 Functional Cookies</strong></h4>
<p>Functional cookies enable the website to provide enhanced functionality and personalization. These cookies may be set by us or by third-party service providers whose services we have integrated into our site.</p>
<ul data-spread="false">
<li>
<p>Example: Remembering your language or region preference.</p>
</li>
</ul>
<h4><strong>2.4 Targeting and Advertising Cookies</strong></h4>
<p>These cookies are used to deliver ads that are relevant to your interests. They also help limit the number of times you see an ad and measure the effectiveness of advertising campaigns.</p>
<ul data-spread="false">
<li>
<p>Example: Cookies used by third-party advertisers like Google Ads and Facebook.</p>
</li>
</ul>
<h3 data-pm-slice="1 3 "><strong>3. Managing Your Cookie Preferences</strong></h3>
<p>You can manage your cookie preferences through the following methods:</p>
<h4><strong>3.1 Browser Settings</strong></h4>
<p>Most web browsers allow you to control cookies through their settings. You can set your browser to block or delete cookies, but this may impact the functionality of certain features on our website.</p>
<ul data-spread="false">
<li>
<p><strong>Google Chrome</strong>: <a>Manage Cookies in Chrome</a></p>
</li>
<li>
<p><strong>Mozilla Firefox</strong>: <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Manage Cookies in Firefox</a></p>
</li>
<li>
<p><strong>Safari</strong>: <a href="https://support.apple.com/en-us/HT201265">Manage Cookies in Safari</a></p>
</li>
<li>
<p><strong>Microsoft Edge</strong>: <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Manage Cookies in Edge</a></p>
</li>
</ul>
<h4><strong>3.2 In-Website Cookie Settings</strong></h4>
<p>We provide a cookie consent banner when you visit our website for the first time. You can choose to accept or reject different types of cookies. To update your preferences at any time, click on the "Cookie Settings" link available at the bottom of our website.</p>
<h4><strong>3.3 Opt-Out of Targeted Advertising</strong></h4>
<p>You can opt out of interest-based advertising through the following platforms:</p>
<ul data-spread="false">
<li>
<p><strong>Google Ads Settings</strong>: <a href="https://adssettings.google.com/">Opt Out</a></p>
</li>
<li>
<p><strong>Facebook Ad Preferences</strong>: <a href="https://www.facebook.com/ads/preferences">Manage Ads</a></p>
</li>
</ul>
<h3 data-pm-slice="1 1 "><strong>4. Changes to This Cookies Settings Page</strong></h3>
<p>We may update this Cookies Settings page periodically to reflect changes in our cookie practices or for other operational, legal, or regulatory reasons. When we make changes, we will update the effective date at the top of the page.</p>
<h3 data-pm-slice="1 1 "><strong>5. Contact Us</strong></h3>
<p>If you have any questions about our use of cookies or your cookie preferences, please contact us at:</p>
<p><strong>Central Books</strong><br />Email: <a>info@centralbooks.in</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </main>
  );
};
export default CookiesSettings;