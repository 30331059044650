import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitleTwo from '../../components/SectionTitle/SectionTitleTwo';
import SingleCategory from '../../components/Category';
import SectionTitle from '../../components/SectionTitle';
import FaqOne from '../../components/Faq';
import faqImg from '../../assets/img/faq/thumb-1.jpg';

import shapeImg from '../../assets/img/category/ed-shape-1.png';

import shapeImg1 from '../../assets/img/about/shape-5-4.png';
import shapeImg2 from '../../assets/img/about/shape-4-4.png';
import shapeImg3 from '../../assets/img/about/shape-4-1.png';
import shapeImg4 from '../../assets/img/about/shape-4-2.png';
import shapeImg5 from '../../assets/img/about/shape-4-3.png';
import shapeImg6 from '../../assets/img/hero/shape-1-2.png';
import shapeImg7 from '../../assets/img/hero/shape-1-3.png';
import aboutImg1 from '../../assets/img/hero/75years.png';
import aboutImg2 from '../../assets/img/hero/thumb-2-2.png';
import studentImg from '../../assets/img/hero/student.png';

const About = () => {
  return (
    <div
      id="it-about"
      className="it-about-5-area fix ed-about-4-wrap p-relative pb-120 mt-120"
    >
      <div className="it-about-5-shape-4 d-none d-md-block">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="container container-3">
        <div className="row align-items-center">
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
            data-wow-duration=".9s"
            data-wow-delay=".5s"
          >
            <div className="ed-hero-thumb-wrap text-center text-md-end  p-relative">
              <div className="ed-hero-thumb-main p-relative">
                <img src={aboutImg2} alt="" />
                <div className="ed-hero-thumb-shape-1 d-none d-md-block">
                  <img src={shapeImg3} alt="" />
                </div>
              </div>
              <div className="ed-hero-thumb-sm">
                <img src={aboutImg1} alt="" />
              </div>
              <div className="ed-hero-thumb-shape-2">
                <img src={shapeImg5} alt="" />
              </div>
              <div className="ed-hero-thumb-shape-3">
                <img src={shapeImg6} alt="" />
              </div>
              <div className="ed-hero-thumb-shape-4">
                <img src={shapeImg7} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight"
            data-wow-duration=".9s"
            data-wow-delay=".7s"
          >
            <div className="it-about-5-right">
              <div className="it-about-5-title-box pb-10">
                <span className="it-section-subtitle-5 orange">
                  <i class="fa-light fa-book"></i>Since 1947
                </span>
                <h4 className="ed-section-title orange">
                We provide
                  <span> complete educational services</span><br />  in India
                </h4>
              </div>

              <div className="it-about-5-text mb-30">
                <p>
                With over 75+ years of experience in the books trade and publishing. Central Books was formed with a view to provide educational books that would enable every student to reach the pinnacle of success in his/her chosen career.<br /><br />
Central Books has a foundation whose pillars are based on highly educated professionals and we are working towards enriching the education system as a whole. The company is working towards a goal to bring transparency within the education industry and to bring convenience to the consumer fraternity. Use of technology enabled tools and services from the crux of the organization.
With its vision and determination, We assure you of best services at all times.
                </p>
              </div>
              <div className="it-feature-button">
                <Link className="ed-btn-radius" to="/about-us">
                Know More..
                </Link>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
