/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import Breadcrumb from '../../components/Breadcrumb';
import ServiceSidebar from '../../components/ServiceSidebar';

import serviceImg from '../../assets/img/service/sv-1.jpg';
import serviceImg2 from '../../assets/img/service/sv-2.jpg';
import Logo from '../../assets/img/logo/logo-white-2.png';
import AboutHomeThree from '../home-3/AboutSection';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/customization.jpg';
import aboutImg2 from '../../assets/img/about/thumb-4-2.jpg';
import titleImg from '../../assets/img/category/title.svg';
import FaqOne from '../../components/Faq';

import faqMainImg from '../../assets/img/about/customizationtwo.jpg';
import faqImg from '../../assets/img/faq/thumb-2.jpg';
import SingleWork from '../../components/Work';

import iconImg1 from '../../assets/img/faq/1.svg';
import iconImg2 from '../../assets/img/faq/2.svg';
import iconImg3 from '../../assets/img/faq/3.svg';
import emailjs from '@emailjs/browser';
import shapeImg from '../../assets/img/contact/shape-2-1.png';


const CertifiedExaminer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);
  const items = [
    {
      icon: 'flaticon-study',
      title: 'Customized Textbooks Aligned with Curriculum',
      description:
        'We create custom textbooks that are specifically designed to match your school’s curriculum, ensuring that every page supports your teaching objectives and enhances the learning experience for students.',
    },
    {
      icon: 'flaticon-exam',
      title: 'Comprehensive Content Curation',
      description:
        'Our team of experts works closely with schools to curate relevant content that addresses specific learning outcomes and aligns with educational standards, ensuring the material is both engaging and informative.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Professional Design and Printing',
      description:
        'From layout and illustrations to typography and cover designs, we provide professional design services to make textbooks and notebooks visually appealing and easy to use. Our high-quality printing ensures durability, clarity, and a polished finish that reflects your schools standards.',
    },
    {
      icon: 'flaticon-pin',
      title: 'Tailored Learning Materials',
      description:
        'Schools can benefit from tailored materials that address their specific educational goals and teaching methodologies. Customized textbooks and notebooks allow teachers to focus on what matters most—effective instruction—while students engage with content that speaks directly to their needs.',
    },
  ];
  const faqitems = [
    {
      id: 'a',
      btnText: 'Enhanced Teaching Efficiency',
      description:
        'With textbooks and notebooks specifically designed for your curriculum, teachers can easily navigate the materials and streamline their lessons.',
      
    },
    {
      id: 'b',
      btnText: 'Consistency Across Materials',
      description:
        'Tailored textbooks and notebooks provide consistency across your school educational resources, ensuring that all students have the same high-quality learning materials.',
      
    },
    {
      id: 'c',
      btnText: 'A Stronger Educational Identity',
      description:
        'Custom-branded materials give your school a unique identity, reinforcing your institution commitment to providing quality education.',
      
    },
  ];
  
 const formRef = useRef();

  return (
    <main>
    <HeaderFour />
    <Breadcrumb title="Customization & Design Services " subTitle="Customization and Design Services" />
    <div
    id="it-about"
    className="it-about-3-area it-about-4-style p-relative grey-bg pt-120 pb-120"
  >
    <div className="ed-about-3-shape-2">
      <img src={shapeImg1} alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-5">
          <div className="ed-about-3-thumb-wrap p-relative">
            <div className="ed-about-3-shape-1 d-none d-md-block">
              <img src={shapeImg2} alt="" />
            </div>
            <div className="ed-about-3-thumb">
              <img src={aboutImg1} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-lg-7">
          <SectionTitle
            itemClass="it-about-3-title-box"
            subTitleClass="it-section-subtitle-5 orange"
            subTitle="Customization & Design Services"
            titleClass="it-section-title-3 pb-30"
            title="Tailored Educational Resources for Every School"
            titleImage={titleImg}
            description="At Central Books, we understand that every school has its own unique teaching methodology and curriculum. That is why we offer Customization and Design services to provide customized textbooks and notebooks that perfectly align with your school educational goals, simplifying the teaching and learning process."
          />

          <div className="it-about-3-mv-box">
            <div className="row">
            <h4>Tailored Solutions for Enhanced Learning</h4>
<p>Our customized educational resources are designed to support both students and teachers in achieving academic excellence. Whether you need personalized textbooks, notebooks, or any other learning materials, we provide comprehensive services that include content curation, design, and high-quality printing.</p>
<h4>Why Choose Our Customization and Design Services?</h4>
              {items.map((items, index) => (
                <div key={index} className="col-xl-12">
                  <div className="it-about-4-list-wrap d-flex align-items-start">
                    <div className="it-about-4-list-icon">
                      <span>
                        <i className={items.icon}></i>
                      </span>
                    </div>
                    <div>
                      <span className="it-about-3-mv-title">
                        {items.title}
                      </span>
                      <p>{items.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="it-faq" className="it-faq-area p-relative pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div
          className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
          data-wow-duration=".9s"
          data-wow-delay=".5s"
        >
          <div className="it-faq-wrap">
            <img src={faqMainImg} alt="" />
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-6 wow animate__fadeInRight"
          data-wow-duration=".9s"
          data-wow-delay=".7s"
        >
          <div className="it-faq-wrap">
            <SectionTitle
              itemClass="it-faq-title-box mb-20"
              subTitleClass="ed-section-subtitle"
              subTitle="Benefits"
              titleClass="it-section-title-5"
              title="The Benefits of Customization and Design"
            />

            <FaqOne items={faqitems} />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6">
          <SectionTitle
            itemClass="it-course-title-box mb-60 text-center"
            subTitleClass="it-section-subtitle-2 white-bg"
            subTitle="Contact us today"
            titleClass="ed-section-title"
            title="Customized Textbooks and Notebooks"
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
          data-wow-duration=".9s"
          data-wow-delay=".3s"
        >
          <p align="justify">At Central Books, we are committed to enhancing the learning experience through customized textbooks and notebooks that make teaching and learning more effective and enjoyable. Let us help you create the perfect resources that reflect your school educational values and objectives.<br/><br/>
          Contact us today to learn more about our Customization and Design services and how we can create personalized educational materials for your school!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="it-contact__area pt-120 pb-120">
      <div className="container">
        <div className="it-contact__wrap fix z-index-3 p-relative">
          <div className="it-contact__shape-1 d-none d-xl-block">
            <img src={shapeImg} alt="" />
          </div>
          <div className="row align-items-end">
            <div className="col-xl-7">
              <div className="it-contact__right-box">
                <div className="it-contact__section-box pb-20">
                  <h4 className="it-contact__title pb-15">Get in Touch with us!</h4>
                  <p>
                  Contact us today to learn more about our Customization and Design services {' '}
                  <br/>Please let us know your requirements, we're Happy to Assist you.
                  </p>
                </div>
                <div className="it-contact__content mb-55">
                  <ul>
                  <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Head Office & Sales Office:</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Ww56NKUkwpte2kwr8"
                              rel="noreferrer"
                            >
                              6-3-553, C1, 2nd Floor, Quena Square,
                              <br />
                              Erramanzil, Somajiguda, Hyderabad-500 082.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">
                              Mon - Sat: 10.00am to 7.00pm
                            </a>
                            <span>Sunday: Holiday</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:08047183344">080 4718 3344</a>
                            <a href="mailto: info@centralbooks.in">
                              info@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/wkvzzR?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Customization & Design Services"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <FooterTwo
      footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
      footerLogo={Logo}
      btnClass="it-btn-white sky-bg"
      copyrightTextClass="it-copyright-text inner-style text-center"
    />
  </main>
  );
};
export default CertifiedExaminer;