import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import shapeImg from '../../assets/img/contact/shape-2-1.png';

const ContactMain = () => {
  const formRef = useRef();
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   emailjs
  //     .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formRef.current, {
  //       publicKey: 'YOUR_PUBLIC_KEY',
  //     })
  //     .then(
  //       () => {
  //         console.log('SUCCESS!');
  //       },
  //       (error) => {
  //         console.log('FAILED...', error.text);
  //       }
  //     );
  // };

  return (
    <main>
      <Breadcrumb title="Contact Us" />

      <div className="it-contact__area pt-120 pb-120">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-7">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">Contact Us</h4>
                    <p>
                    Contact Us for your requirements, we’re Happy to Asssit you.{' '}
                    </p>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Head Office & Sales Office:</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/MudRAwKrP5EceSrWA" rel="noreferrer"
                            >
                              CBS HUB Private Limited<br/>
                              6-3-553, C1, 2nd Floor, Quena Square, Erramanzil,
                              Somajiguda, <br/>Hyderabad-500 082.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#">Mon - Sat: 9.00am to 6.00pm</a>
                            <span>[Sunday Holiday]</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:08047183344">080 4718 3344</a>
                            <a href="mailto:info@centralbooks.in">
                            info@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="it-contact__bottom-box d-flex align-items-center justify-content-between">
                    <div className="it-contact__scrool smooth">
                      <a href="#">
                        <i className="fa-solid fa-arrow-down"></i>Customer Care
                      </a>
                    </div>
                    <div className="it-footer-social">
                    <a href="https://www.facebook.com/centralbooks.store" target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/centralbooksonline/" target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://in.pinterest.com/centralbooks" target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-pinterest-p"></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCcOXd8hwVR501Iv0YoOcD5Q" target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-youtube-square"></i>
                    </a>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 it-contact__form-box mb-60">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/mRkjrd?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Contact Us"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContactMain;
