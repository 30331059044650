/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import Logo from '../../assets/img/logo/logo-white-2.png';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/BulkSupplies.jpg';
import aboutImg2 from '../../assets/img/about/bulksuppliestwo.jpg';
import titleImg from '../../assets/img/category/title.svg';
import FaqOne from '../../components/Faq';

import faqMainImg from '../../assets/img/about/bulksupplies-two.jpg';
import faqImg from '../../assets/img/faq/operations.png';
import emailjs from '@emailjs/browser';
import shapeImg from '../../assets/img/contact/shape-2-1.png';


const BulkSuppliesKits = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const items = [
    {
      icon: 'flaticon-study',
      title: 'Student-Specific Packed Kits',
      description:
        'We simplify academic material distribution with customized student kits, including: Curriculum-specific textbooks, School-branded notebooks, Essential stationery items',
    },
    {
      icon: 'flaticon-ux-design',
      title: 'Custom Designed',
      description:
        'These kits are tailored to each class and curriculum, ensuring students are ready to excel from day one.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Timely Delivery',
      description:
        'Central Books ensures timely delivery of academic supplies, easing inventory management so schools can focus on their mission.',
    },
    {
      icon: 'flaticon-check-mark',
      title: 'Transparent Collaboration',
      description:
        'Schools distribute the kits and make payments to Central Books based on an agreed timeline, ensuring a hassle-free process.',
    },
    {
      icon: 'flaticon-promotion',
      title: 'Custom Branding and Packaging',
      description:
        'Our kits offer more than just books, featuring school-branded notebooks, covers, and personalized touches to enhance your school’s identity.',
    },
  ];
  const faqitems = [
    {
      id: 'a',
      btnText: 'Streamlined Operations',
      description:
        'Schools can avoid the complexities of procurement, sorting, and distribution, as our team handles the heavy lifting.',
      
    },
    {
      id: 'b',
      btnText: 'Time-Saving',
      description:
        'Packed kits are ready for immediate distribution, which saves valuable time for school staff.',
      
    },
    {
      id: 'c',
      btnText: 'Convenience for Parents',
      description:
        'Parents no longer have to shop around for individual items; everything their child needs is included in one kit.',
      
    },
    {
      id: 'd',
      btnText: 'Quality Assurance',
      description:
        'From textbooks to stationery, we ensure that every item meets the highest standards and aligns with the academic goals of the institution.',
      
    },
  ];
  
 const formRef = useRef();

  return (
    <main>
      <HeaderFour />
      <Breadcrumb
        title="Bulk Supplies & Kits "
        subTitle="Bulk Supplies & Kits"
      />
      <div
        id="it-about"
        className="it-about-3-area it-about-4-style p-relative grey-bg pt-120 pb-120"
      >
        <div className="ed-about-3-shape-2">
          <img src={shapeImg1} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="ed-about-3-thumb-wrap p-relative">
                <div className="ed-about-3-shape-1 d-none d-md-block">
                  <img src={shapeImg2} alt="" />
                </div>
                <div className="ed-about-3-thumb">
                  <img src={aboutImg1} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <SectionTitle
                itemClass="it-about-3-title-box"
                subTitleClass="it-section-subtitle-5 orange"
                subTitle="Bulk Supplies and Kits"
                titleClass="it-section-title-3 pb-30"
                title="Simplifying Academic Needs for Schools"
                titleImage={titleImg}
                description="At Central Books, we believe that education is the cornerstone of a brighter future. With over 75 years of experience, we’ve dedicated ourselves to providing seamless and efficient solutions for academic institutions. Our bulk supplies and packed kit service is tailored to meet the unique needs of schools, ensuring that students and educators have the resources they need—right on time."
              />

              <div className="it-about-3-mv-box">
                <div className="row">
                  {items.map((items, index) => (
                    <div key={index} className="col-xl-12">
                      <div className="it-about-4-list-wrap d-flex align-items-start">
                        <div className="it-about-4-list-icon">
                          <span>
                            <i className={items.icon}></i>
                          </span>
                        </div>
                        <div>
                          <span className="it-about-3-mv-title">
                            {items.title}
                          </span>
                          <p>{items.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="it-faq" className="it-faq-area p-relative pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
              data-wow-duration=".9s"
              data-wow-delay=".5s"
            >
              <div className="it-faq-wrap">
                <img src={faqMainImg} alt="" />
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 wow animate__fadeInRight"
              data-wow-duration=".9s"
              data-wow-delay=".7s"
            >
              <div className="it-faq-wrap">
                <SectionTitle
                  itemClass="it-faq-title-box mb-20"
                  subTitleClass="ed-section-subtitle"
                  subTitle="Benefits"
                  titleClass="it-section-title-5"
                  title="The Benefits for Schools and Students"
                />

                <FaqOne items={faqitems} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <SectionTitle
                itemClass="it-course-title-box mb-60 text-center"
                subTitleClass="it-section-subtitle-2 white-bg"
                subTitle="OUR feature"
                titleClass="ed-section-title"
                title="Supporting Education, One Kit at a Time"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
              data-wow-duration=".9s"
              data-wow-delay=".3s"
            >
              <p align="justify">
                With a 40,000 square-foot warehouse and a team of over 250
                dedicated professionals, we have the infrastructure to cater to
                over 1 Million students annually. Every packed kit is assembled
                with care and precision, using the latest technology to
                guarantee accuracy and quality.
                <br />
                <br />
                To ensure personalized service, we assign a Fulfillment Manager
                to each school. This single point of contact ensures smooth
                communication, understands the schools' unique needs, and
                oversees the entire procurement process. At Central Books, we
                are not just suppliers; we are partners in education. <br />
                <br />
                By simplifying the supply chain, enhancing transparency, and
                leveraging cutting-edge tools, we help schools create an
                enriched learning environment for their students. Together,
                let's build a future where academic success is accessible and
                achievable for all.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-120 pb-120">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-7">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">
                      Get in Touch with us!
                    </h4>
                    <p>
                      Let's make education easier, one kit at a time!
                      <br />
                      For any inquiries about our bulk supplies and kits,
                      contact us below. we're happy to assist you.
                    </p>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className="it-section-subtitle-5 orange">
                              Head Office & Sales Office:
                            </span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Ww56NKUkwpte2kwr8"
                              rel="noreferrer"
                            >
                              6-3-553, C1, 2nd Floor, Quena Square,
                              <br />
                              Erramanzil, Somajiguda, Hyderabad-500 082.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">
                              Mon - Sat: 10.00am to 7.00pm
                            </a>
                            <span>Sunday: Holiday</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:08047183344">080 4718 3344</a>
                            <a href="mailto: info@centralbooks.in">
                              info@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/wbvzzL?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Bulk Supplies & Kits"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};
export default BulkSuppliesKits;
