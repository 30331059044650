import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../../assets/img/work/work-1.svg';

const SingleWork = (props) => {
  const { itemClass, iconImage, title, description } = props;
  return (
    <div className={itemClass ? itemClass : 'it-work-item text-center'}>
      <div className="it-work-icon">
        <span>
          <img src={iconImage ? iconImage : Image} alt="" />
        </span>
      </div>
      <div className="it-work-content">
        <h4 className="it-work-title-sm">
          {title ? title : 'start course'}
        </h4>
        <p>
          {description
            ? description
            : 'We have sufficient man-power of around 250 people to ensure timely supplies. We are equipped with high quality, latest machines which help in making customized kits class wise for schools there by ensuring timely supply to schools.'}
        </p>
      </div>
    </div>
  );
};
export default SingleWork;
