import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import SectionTitle from '../../components/SectionTitle';
import SingleTestimonial from '../../components/Testimonial';

import testimonialBG from '../../assets/img/testimonial/testimonial-bg.jpg';

const Testimonial = () => {
  const sliderOption = {
    speed: 1500,
    loop: true,
    slidesPerView: '3',
    spaceBetween: 50,
    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      1400: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  };
  return (
    <div
      className="it-testimonial-area ed-testimonial-ptb fix p-relative"
      style={{ backgroundImage: `url(${testimonialBG})` }}
    >
      <div className="container">
        <div className="it-testimonial-title-wrap mb-90">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <SectionTitle
                itemClass="it-testimonial-title-box text-center"
                subTitle="Vision & Mission"
                title="Our Vision & Mission"
              />
            </div>
          </div>
        </div>
        <div className="it-about-3-mv-box">
              <div className="row">
              <div className="col-xl-6 col-md-6">
                  <div className="it-about-3-mv-item">
                    <span className="it-about-3-mv-title">OUR VISION:</span>
                    <p>
                    Our vision at Central Books is to empower and enhance the school education system by delivering exceptional and comprehensive educational solutions. We strive to be the ultimate one-stop solution for all academic and co-curricular needs within the K-12 segment, enabling schools to achieve academic excellence and providing a holistic educational experience.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="it-about-3-mv-item">
                    <span className="it-about-3-mv-title">OUR MISSION:</span>
                    <p>
                    Our mission is to provide Central Books services to private schools in India, with a specific focus on ICSE, CBSE, IB, and IGCSE curriculums. By 2027, we aim to serve 10% of the estimated operational schools in India, which is approximately 3,000 schools out of the total 30,000 plus private schools.
                    Our aim is to empower educators, support students, and enhance the overall quality of education in India.
                    </p>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  );
};
export default Testimonial;
