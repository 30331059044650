/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import Breadcrumb from '../../components/Breadcrumb';
import ServiceSidebar from '../../components/ServiceSidebar';

import serviceImg from '../../assets/img/service/sv-1.jpg';
import serviceImg2 from '../../assets/img/service/sv-2.jpg';
import Logo from '../../assets/img/logo/logo-white-2.png';
import AboutHomeThree from '../home-3/AboutSection';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/tuckshop.jpg';
import aboutImg2 from '../../assets/img/about/thumb-4-2.jpg';
import titleImg from '../../assets/img/category/title.svg';
import FaqOne from '../../components/Faq';

import faqMainImg from '../../assets/img/about/tuckshoptwo.jpg';
import faqImg from '../../assets/img/faq/thumb-2.jpg';
import SingleWork from '../../components/Work';

import iconImg1 from '../../assets/img/faq/1.svg';
import iconImg2 from '../../assets/img/faq/2.svg';
import iconImg3 from '../../assets/img/faq/3.svg';
import emailjs from '@emailjs/browser';
import shapeImg from '../../assets/img/contact/shape-2-1.png';


const CertifiedExaminer = () => {
  const [isOpen, setIsOpen] = useState(false);
   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const items = [
    {
      icon: 'flaticon-study',
      title: 'Comprehensive Management',
      description:
        'Our team oversees the entire sales process, from stocking to selling, ensuring everything runs smoothly.',
    },
    {
      icon: 'flaticon-exam',
      title: 'Professional Support',
      description:
        'Trained sales staff operate the stalls, providing prompt and friendly service to students and parents.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Streamlined Operations',
      description:
        'We handle the burden of sales, collection, and accounting, allowing schools to focus on their core mission—educating students.',
    },
  ];
  const faqitems = [
    {
      id: 'a',
      btnText: 'Convenience for Schools',
      description:
        'Schools no longer need to allocate staff or resources to manage the bookstall. Central Books takes care of every detail.',
      
    },
    {
      id: 'b',
      btnText: 'Ease for Parents and Students',
      description:
        'Parents can quickly purchase books, notebooks, and stationery from the on-campus tuck shop without having to search elsewhere.',
      
    },
    {
      id: 'c',
      btnText: 'Tailored Stock',
      description:
        'The tuck shop is stocked with materials specific to the schools curriculum, ensuring students have the exact resources they need.',
      
    },
    {
      id: 'd',
      btnText: 'Hassle-Free Accounting',
      description:
        'Our team manages all transactions and provides transparent records, ensuring that schools can trust their sales are in expert hands.',
      
    },
  ];
  
 const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formRef.current, {
        publicKey: 'YOUR_PUBLIC_KEY',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <main>
    <HeaderFour />
    <Breadcrumb title="Tuck Shop Sales " subTitle="Tuck Shop Sales" />
    <div
    id="it-about"
    className="it-about-3-area it-about-4-style p-relative grey-bg pt-120 pb-120"
  >
    <div className="ed-about-3-shape-2">
      <img src={shapeImg1} alt="" />
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-5">
          <div className="ed-about-3-thumb-wrap p-relative">
            <div className="ed-about-3-shape-1 d-none d-md-block">
              <img src={shapeImg2} alt="" />
            </div>
            <div className="ed-about-3-thumb">
              <img src={aboutImg1} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-lg-7">
          <SectionTitle
            itemClass="it-about-3-title-box"
            subTitleClass="it-section-subtitle-5 orange"
            subTitle="Tuck Shop Sales"
            titleClass="it-section-title-3 pb-30"
            title="Simplifying On-Campus Academic Supplies"
            titleImage={titleImg}
            description="At Central Books, we go beyond just delivering educational materials—we bring convenience and efficiency to schools through our Tuck Shop Sales service. A tuck shop, essentially a school bookstall, is a dedicated space where students and parents can conveniently purchase academic materials directly on campus."
          />

          <div className="it-about-3-mv-box">
            <div className="row">
            <h4>How We Make It Easy for Schools</h4>
<p>Managing the sale of academic supplies can be time-consuming and complex for schools. That’s why we take full responsibility for the tuck shop operations:</p>
              {items.map((items, index) => (
                <div key={index} className="col-xl-12">
                  <div className="it-about-4-list-wrap d-flex align-items-start">
                    <div className="it-about-4-list-icon">
                      <span>
                        <i className={items.icon}></i>
                      </span>
                    </div>
                    <div>
                      <span className="it-about-3-mv-title">
                        {items.title}
                      </span>
                      <p>{items.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="it-faq" className="it-faq-area p-relative pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div
          className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
          data-wow-duration=".9s"
          data-wow-delay=".5s"
        >
          <div className="it-faq-wrap">
            <img src={faqMainImg} alt="" />
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-6 wow animate__fadeInRight"
          data-wow-duration=".9s"
          data-wow-delay=".7s"
        >
          <div className="it-faq-wrap">
            <SectionTitle
              itemClass="it-faq-title-box mb-20"
              subTitleClass="ed-section-subtitle"
              subTitle="Benefits"
              titleClass="it-section-title-5"
              title="Benefits of Our Tuck Shop Sales"
            />

            <FaqOne items={faqitems} />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6">
          <SectionTitle
            itemClass="it-course-title-box mb-60 text-center"
            subTitleClass="it-section-subtitle-2 white-bg"
            subTitle="Join us"
            titleClass="ed-section-title"
            title="We bridge the gap between schools and students"
          />
        </div>
      </div>
      <div className="row">
        <div
          className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
          data-wow-duration=".9s"
          data-wow-delay=".3s"
        >
          <p align="justify">With Tuck Shop Sales, we bridge the gap between schools and students by offering a convenient and well-managed solution for on-campus academic needs. It's another way Central Books supports schools in creating a seamless educational experience.<br/><br/>
          For more information about setting up a tuck shop in your school, contact us today!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="it-contact__area pt-120 pb-120">
      <div className="container">
        <div className="it-contact__wrap fix z-index-3 p-relative">
          <div className="it-contact__shape-1 d-none d-xl-block">
            <img src={shapeImg} alt="" />
          </div>
          <div className="row align-items-end">
            <div className="col-xl-7">
              <div className="it-contact__right-box">
                <div className="it-contact__section-box pb-20">
                  <h4 className="it-contact__title pb-15">Get in Touch with us!</h4>
                  <p>
                  Contact us today to learn more about our Tuck Shop Sales.{' '}
                  <br/>Please let us know your requirements, we're Happy to Assist you.
                  </p>
                </div>
                <div className="it-contact__content mb-55">
                  <ul>
                  <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Head Office & Sales Office:</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Ww56NKUkwpte2kwr8"
                              rel="noreferrer"
                            >
                              6-3-553, C1, 2nd Floor, Quena Square,
                              <br />
                              Erramanzil, Somajiguda, Hyderabad-500 082.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">
                              Mon - Sat: 10.00am to 7.00pm
                            </a>
                            <span>Sunday: Holiday</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:08047183344">080 4718 3344</a>
                            <a href="mailto: info@centralbooks.in">
                              info@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/wdvzvo?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Tuck Shop Sales"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <FooterTwo
      footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
      footerLogo={Logo}
      btnClass="it-btn-white sky-bg"
      copyrightTextClass="it-copyright-text inner-style text-center"
    />
  </main>
  );
};
export default CertifiedExaminer;