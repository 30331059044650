import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import SectionTitle from '../../components/SectionTitle';
import SingleTestimonial from '../../components/Testimonial';

import testimonialBG from '../../assets/img/testimonial/testimonial-bg.jpg';

import shapeImg1 from '../../assets/img/contact/shape-1-1.png';
import shapeImg2 from '../../assets/img/contact/shape-1-2.png';
import shapeImg3 from '../../assets/img/contact/shape-1-3.png';
import shapeImg4 from '../../assets/img/contact/shape-1-4.png';
import titleImg from '../../assets/img/category/title.svg';
import contactBG from '../../assets/img/contact/bg-5.jpg';
import emailjs from '@emailjs/browser';

const TestimonialOne = () => {
  const formRef = useRef();
  // Emailjs Setup
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formRef.current, {
        publicKey: 'YOUR_PUBLIC_KEY',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <div
      className="it-testimonial-area ed-testimonial-ptb fix p-relative"
      style={{ backgroundImage: `url(${testimonialBG})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="ed-testimonial-wrapper">
            <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xl-7 col-lg-7 wow animate__fadeInLeft"
            data-wow-duration=".9s"
            data-wow-delay=".5s"
          >
            <div className="it-contact-left">
              <SectionTitle
                itemClass="it-contact-title-box pb-20"
                subTitleClass="it-section-subtitle-2 white-bg"
                subTitle=" CLIENTS"
                titleClass="it-section-title-3"
                title="Our most honorable Clients"
                titleImage={titleImg}
              />
              <div className="it-contact-text pb-15">
                <p>
                We work hand-in-hand with 450+ schools, including some of the most prestigious institutions. We cater to over 9 million students.
                </p>
                <a class="ed-btn-radius hover-2 sm" href="/clients">View Our Clients</a>
              </div>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-5 wow animate__fadeInRight"
            data-wow-duration=".9s"
            data-wow-delay=".5s"
          >
            <div
              className="it-contact-wrap"
              style={{ borderRadius: `20px` }}
            >
              <span className="it-section-subtitle-5 orange">
                  get in touch
              </span>
              <p className="pb-15" style={{ color: `white` }}>
              Please contact us for your educational requirements.
              </p>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 mb-15">
                    <div className="it-contact-input-box">
                      <input type="text" placeholder="Your Name" />
                    </div>
                  </div>
                  <div className="col-12 mb-15">
                    <div className="it-contact-input-box">
                      <input type="email" placeholder="Your Email" />
                    </div>
                  </div>
                  <div className="col-12 mb-15">
                    <div className="it-contact-input-box">
                      <input type="text" placeholder="Phone" />
                    </div>
                  </div>
                  <div className="col-12 mb-30">
                    <div className="it-contact-textarea-box">
                      <textarea placeholder="Message"></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="ed-btn-radius">
                  <span>submit now</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TestimonialOne;
