import React from 'react';
import About from './AboutSection';
import Banner from './HomeFourBanner';
import ServiceBulkSupplies from './ServiceBulkSupplies';
import ServiceExclusive from './ServiceExclusive';
import ServiceNotebook from './ServiceNotebook';
import ServiceStationery from './ServiceStationery';
import CareerThree from './CareerSectionThree';
import TestimonialOne from './TestimonialSectionOne';
import FunFactOne from './FunFactSectionOne';
import Work from './WorkSection';

const HomeMain = () => {
  return (
    <main>
      <Banner />
      <About />
      <Work />
      <ServiceBulkSupplies />
      <ServiceExclusive />
      <ServiceNotebook />
      <ServiceStationery />
      <CareerThree />
      <FunFactOne />
      <TestimonialOne />
    </main>
  );
};
export default HomeMain;
