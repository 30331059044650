/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import SectionTitle from '../../components/SectionTitle';

import Logo from '../../assets/img/logo/logo-white-2.png';

import Img1 from '../../assets/img/gallery/CB1.JPG';
import Img2 from '../../assets/img/gallery/CB2.JPG';
import Img3 from '../../assets/img/gallery/CB3.JPG';
import Img4 from '../../assets/img/gallery/CB4.JPG';
import Img5 from '../../assets/img/gallery/CB5.JPG';
import Img6 from '../../assets/img/gallery/CB6.JPG';
import Img7 from '../../assets/img/gallery/CB7.JPG';
import Img8 from '../../assets/img/gallery/CB8.JPG';

const Gallery = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8];

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => setIsLightboxOpen(false);

  const goToNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const goToPrevious = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Gallery" subTitle="Gallery" />
      <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-20 pb-120 grey-bg-4">
        <div className="container">
          {/* <div className="row justify-content-center">
            <div className="col-xl-6">
              <SectionTitle
                itemClass="it-course-title-box mb-60 text-center"
                subTitleClass="it-section-subtitle-2 white-bg"
                subTitle="Gallery"
                titleClass="ed-section-title"
                title="Gallery"
              />
            </div>
          </div> */}
          <div className="gallery-container">
            {/* Gallery Grid */}
            <div className="gallery-grid">
              {images.map((img, index) => (
                <div
                  key={index}
                  className="gallery-item"
                  onClick={() => openLightbox(index)}
                >
                  <img src={img} alt={`Gallery ${index + 1}`} />
                </div>
              ))}
            </div>

            {/* Lightbox */}
            {isLightboxOpen && (
              <div className="lightbox-overlay">
                <button className="lightbox-close" onClick={closeLightbox}>
                  &times;
                </button>
                <button className="lightbox-prev" onClick={goToPrevious}>
                  &#10094;
                </button>
                <div className="lightbox-content">
                  <img
                    src={images[currentImageIndex]}
                    alt={`Image ${currentImageIndex + 1}`}
                  />
                </div>
                <button className="lightbox-next" onClick={goToNext}>
                  &#10095;
                </button>
                {/* Thumbnails */}
                <div className="lightbox-thumbnails">
                  {images.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Thumbnail ${index + 1}`}
                      className={
                        currentImageIndex === index ? 'active-thumbnail' : ''
                      }
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};

export default Gallery;