/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import Logo from '../../assets/img/logo/logo-white-2.png';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/certifiedexaminer.jpg';
import aboutImg2 from '../../assets/img/about/thumb-4-2.jpg';
import titleImg from '../../assets/img/category/title.svg';
import FaqOne from '../../components/Faq';

import faqMainImg from '../../assets/img/about/certifiedexaminertwo.jpg';
import faqImg from '../../assets/img/faq/thumb-2.jpg';
import emailjs from '@emailjs/browser';
import shapeImg from '../../assets/img/contact/shape-2-1.png';
import examinarlogo from '../../assets/img/about/thecertifiedexaminer.png';


const CertifiedExaminer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);
  const items = [
    {
      icon: 'flaticon-study',
      title: 'Exam Preparation Guidance',
      description:
        'We provide expert guidance to help you prepare for your Cambridge English exams. Our preparation resources are designed to enhance your language skills, boost your confidence, and ensure that you are fully equipped for success on test day.',
    },
    {
      icon: 'flaticon-exam',
      title: 'Flexible Exam Scheduling',
      description:
        'With our streamlined scheduling process, you can choose exam dates that fit your busy calendar, ensuring that your testing experience is both convenient and stress-free.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Certified Assessments Tailored to All Levels',
      description:
        'Whether you are just starting to learn English or looking to refine your advanced skills, we offer assessments that cater to every proficiency level, from beginners to experts.',
    },
    {
      icon: 'flaticon-pin',
      title: 'Global Recognition for Academic and Professional Growth',
      description:
        'Our Cambridge English proficiency tests are recognized by universities, employers, and governments worldwide, offering you the credentials you need to advance academically and professionally. Schools that integrate Cambridge English into their curriculum enhance their students’ language learning and prepare them for global opportunities.',
    },
  ];
  const faqitems = [
    {
      id: 'a',
      btnText: 'Trusted by Educational Institutions',
      description:
        'As an authorized partner of Cambridge English, we uphold the highest standards in language testing, providing trusted and credible results that are recognized globally.',
      
    },
    {
      id: 'b',
      btnText: 'Dedicated to Your Success',
      description:
        'We are not just an exam center; we are a community invested in your success. We guide, support, and encourage every step of your language learning journey, helping you achieve the proficiency needed for future academic or career success.',
      
    },
    {
      id: 'c',
      btnText: 'Supporting Global Aspirations',
      description:
        'Whether you aim to study abroad, expand your professional prospects, or simply improve your communication skills, our assessments provide the certification that can unlock doors to a world of opportunities.',
      
    },
  ];
  
 const formRef = useRef();
 const toggleModal = () => setIsModalOpen(!isModalOpen);
 
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="The Certified Examiner" subTitle="The Certified Examiner" />
      <div id="it-about" className="it-about-3-area it-about-4-style p-relative grey-bg pt-120 pb-120">
        <div className="ed-about-3-shape-2">
          <img src={shapeImg1} alt="" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 pb-60">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <img
                  src={examinarlogo}
                  alt=""
                  width="320px"
                />
              </div>
              <SectionTitle
                itemClass="it-about-3-title-box"
                subTitleClass="it-section-subtitle-5 orange"
                subTitle="The Certified Examiner"
                titleClass="it-section-title-3 pb-30"
                title="Unlocking Global Opportunities with Cambridge English"
                titleImage={titleImg}
                description="At Central Books, we believe that language proficiency is the key to opening doors to a world of opportunities. As an authorized partner of Cambridge English Assessment, we offer internationally recognized English proficiency tests that are designed to assess language skills at every level, helping students and professionals achieve their goals."
              />
              <h4>Empowering Students and Professionals</h4>
              <p>
                Our Certified Examiner Center is more than just a testing
                facility; we are a community dedicated to supporting your growth
                and success. Whether you are a student preparing for higher
                education or a professional seeking career advancement, our
                services are tailored to guide you through the process of
                achieving English language proficiency.
              </p>
              <div className="ed-header-button text-center d-none d-lg-block">
                            <Link className="ed-btn-theme theme-2" data-tally-open="mVDxKE" data-tally-layout="modal" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">
                            Download Brochure
                              <i className="fa-solid fa-arrow-down"></i>
                            </Link>
                          </div>
            </div>
            <div className="col-xl-5 col-lg-5">
              <div className="ed-about-3-thumb-wrap p-relative">
                <div className="ed-about-3-shape-1 d-none d-md-block">
                  <img src={shapeImg2} alt="" />
                </div>
                <div className="ed-about-3-thumb">
                  <img src={aboutImg1} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7">
              <div className="it-about-3-mv-box">
                <div className="row">
                  <h4>Comprehensive Services for Every Learner</h4>
                  {items.map((items, index) => (
                    <div key={index} className="col-xl-12">
                      <div className="it-about-4-list-wrap d-flex align-items-start">
                        <div className="it-about-4-list-icon">
                          <span>
                            <i className={items.icon}></i>
                          </span>
                        </div>
                        <div>
                          <span className="it-about-3-mv-title">
                            {items.title}
                          </span>
                          <p>{items.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="it-faq" className="it-faq-area p-relative pt-120 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-xl-6 col-lg-6 wow animate__fadeInLeft"
              data-wow-duration=".9s"
              data-wow-delay=".5s"
            >
              <div className="it-faq-wrap">
                <img src={faqMainImg} alt="" />
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 wow animate__fadeInRight"
              data-wow-duration=".9s"
              data-wow-delay=".7s"
            >
              <div className="it-faq-wrap">
                <SectionTitle
                  itemClass="it-faq-title-box mb-20"
                  subTitleClass="ed-section-subtitle"
                  subTitle="Benefits"
                  titleClass="it-section-title-5"
                  title="Why Choose The Certified Examiner Center?"
                />

                <FaqOne items={faqitems} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-wrok-area it-wrok-bg ed-work-style-2 pt-120 pb-90 grey-bg-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <SectionTitle
                itemClass="it-course-title-box mb-60 text-center"
                subTitleClass="it-section-subtitle-2 white-bg"
                subTitle="Join us"
                titleClass="ed-section-title"
                title="Join us at The Certified Examiner"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-lg-12 col-md-12 mb-30 wow animate__fadeInUp"
              data-wow-duration=".9s"
              data-wow-delay=".3s"
            >
              <p align="justify">
                Join us at The Certified Examiner Center and take the next step
                towards your future with confidence. Whether you're a student
                preparing for higher education or a professional pursuing career
                growth, we are here to help you succeed in your English
                proficiency journey.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-120 pb-120">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-7">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">
                      Get in Touch with us!
                    </h4>
                    <p>
                      Contact us today to learn more about our services and
                      schedule your exam! <br />
                      Please let us know your requirements, we're Happy to
                      Assist you.
                    </p>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                    <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Our Address</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/Tqwzy8MyXJb9SErQ9"
                            >
                               #3-6-418, Street No. 3, Himayath Nagar, <br />
                               Hyderabad - 500 029.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#">Monday to Saturday: 10.00am to 7.00pm</a>
                            <span>[Sunday Holiday]</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:+919154827779">+91 9154827779</a>
                            <a href="mailto:info@thecertifiedexaminer.in">
                            info@thecertifiedexaminer.in
                            </a>
                            <a href="mailto:mechinenishyam@centralbooks.in">
                            mechinenishyam@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/w5MVGN?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="The Certified Examiner"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};
export default CertifiedExaminer;
