import React from 'react';
import SingleWork from '../../components/Work';

import iconImg1 from '../../assets/img/faq/1.svg';
import iconImg2 from '../../assets/img/faq/2.svg';
import iconImg3 from '../../assets/img/faq/3.svg';

const Work = () => {
  return (
    <div
      id="it-work"
      className="it-wrok-area fix it-wrok-bg ed-work-style-2 ed-work-style-3 pt-10 pb-90"
    >
      <div className="container container-3">
        <div className="row">
          <div
            className="col-xl-4 col-lg-4 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration=".9s"
            data-wow-delay=".3s"
          >
            <SingleWork
              itemClass="it-work-item"
              iconImage={iconImg1}
              title="250+ Professional Team"
              description="We have workforce of around 250 people to ensure timely supplies. We are equipped with high-quality, latest machines that help in making customized kits class-wise for schools, thereby ensuring timely supplies to them."
            />
          </div>
          <div
            className="col-xl-4 col-lg-4 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration=".9s"
            data-wow-delay=".5s"
          >
            <SingleWork
              itemClass="it-work-item"
              iconImage={iconImg2}
              title="Best in Educational Industry"
              description="By providing individualized supply chain management, we put our clients first. A specialized Fulfillment Manager serves as a single point of contact for each significant customer, facilitating easy communication, comprehending school requirements, and providing timely support."
            />
          </div>
          <div
            className="col-xl-4 col-lg-4 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration=".9s"
            data-wow-delay=".7s"
          >
            <SingleWork
              itemClass="it-work-item"
              iconImage={iconImg3}
              title="75+ Years of Educational Service"
              description="Since 1947, we have been providing the educational services and Central Books has a spacious 40,000 square-foot warehouse, that enables centralized management of predetermined inventory levels."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Work;
