/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import Logo from '../../assets/img/logo/logo-white-2.png';
import shapeImg from '../../assets/img/contact/shape-2-1.png';


const RetailStores = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Retail Stores" subTitle="Retail Stores" />

      <div className="it-contact__area pt-60 pb-30">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">Abids Bookstore</h4>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Abids Retail Store:</span>
                            <a target="_blank"
                              href="https://maps.app.goo.gl/odw4qCaG6gwo2Tu17" rel="noreferrer"
                            >
                              H.No. 5-9-186, Behind SBH Main Branch, Chapel Road,<br/> Gun Foundry, Abids,<br/> Hyderabad, Telangana - 500001.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">Mon - Sat: 10.00am to 9.00pm</a>
                            <span>Sunday: 10.00am to 8.00pm</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04066468646">040 6646 8646</a>
                            <a href="tel:7032235370">70322 35370</a>
                            <a href="mailto:abids@centralbooks.in">
                            abids@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Get Direction</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/odw4qCaG6gwo2Tu17" rel="noreferrer"
                            >
                              Google Maps Direction Link
                            </a>
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15229.412562489862!2d78.4569253554199!3d17.394832300000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97620b19443b%3A0x77a41cd23c6dfcb0!2sCentral%20Books%20Abids!5e0!3m2!1sen!2sin!4v1737106033102!5m2!1sen!2sin"
                          width="400"
                          height="300"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-60 pb-30">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">Ameerpet Bookstore</h4>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Ameerpet Retail Store:</span>
                            <a target="_blank"
                              href="https://maps.app.goo.gl/hrzMGP7c8KRK5fg3A" rel="noreferrer"
                            >
                              6, Saadath Manzil Colony Road, opposite Hotel Green Park,<br/> adjoining Mallik Wines, Greenlands,<br/> Ameerpet, Hyderabad, Telangana 500016.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">Mon - Sat: 10.00am to 9.00pm</a>
                            <span>Sunday: 10.00am to 8.00pm</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04023400789">040 23400789</a>
                            <a href="tel:8341034828">8341034828</a>
                            <a href="mailto:ameerpet@centralbooks.in">
                            ameerpet@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Get Direction</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/hrzMGP7c8KRK5fg3A" rel="noreferrer"
                            >
                              Google Maps Direction Link
                            </a>
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.501173258142!2d78.45087197383033!3d17.43571060141384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90b7cf6fd931%3A0x6ad8137fd975d18e!2sCentral%20Book%20Shop!5e0!3m2!1sen!2sin!4v1737110708055!5m2!1sen!2sin"
                          width="400"
                          height="300"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-60 pb-30">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">Mallapur Bookstore</h4>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Mallapur Retail Store:</span>
                            <a target="_blank"
                              href="https://maps.app.goo.gl/kNrXDYfZMxe9AfB9A" rel="noreferrer"
                            >
                              IDA Nacharam Rd Number 9,<br/> Industrial Development, Mallapur,<br/> Hyderabad, Secunderabad, Telangana 500076.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">Mon - Sat: 10.00am to 9.00pm</a>
                            <span>Sunday: 10.00am to 8.00pm</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:9154188250">9154188250</a>
                            <a href="mailto:mallapur@centralbooks.in">
                            mallapur@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Get Direction</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/kNrXDYfZMxe9AfB9A" rel="noreferrer"
                            >
                              Google Maps Direction Link
                            </a>
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.4128673731243!2d78.55995897383048!3d17.439942301291634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9bb341266675%3A0xfc05b7bcb0f5b9b7!2scentral%20book%20shop!5e0!3m2!1sen!2sin!4v1737111616553!5m2!1sen!2sin"
                          width="400"
                          height="300"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-contact__area pt-60 pb-30">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">Nizampet Bookstore</h4>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span className='it-section-subtitle-5 orange'>Nizampet Retail Store:</span>
                            <a target="_blank"
                              href="https://maps.app.goo.gl/A91Xc4p6S4W9xzzv9" rel="noreferrer"
                            >
                              Nizampet Road Beside Sanghamitra School, Union Bank Building, 1st Flore,<br/> Nizampet, Hyderabad, Telangana 500085.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#" rel="noreferrer">Mon - Sat: 10.00am to 9.00pm</a>
                            <span>Sunday: 10.00am to 8.00pm</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04029800417">040 29800417</a>
                            <a href="tel:9154188207">9154188207</a>
                            <a href="mailto:nizampet@centralbooks.in">
                            nizampet@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="it-contact__right-box">
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Get Direction</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/A91Xc4p6S4W9xzzv9" rel="noreferrer"
                            >
                              Google Maps Direction Link
                            </a>
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.0866380644757!2d78.38542197383211!3d17.503377199455947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91428b747bd1%3A0xc0a63766af417fb6!2sCentral%20Books%20Shop%20Nizampet!5e0!3m2!1sen!2sin!4v1737112045475!5m2!1sen!2sin"
                          width="400"
                          height="300"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};
export default RetailStores;
