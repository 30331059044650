import React from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import SectionTitle from '../../components/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';
import shapeImg from '../../assets/img/contact/international-Schools.jpg';
import shapeImg2 from '../../assets/img/contact/CBSE-Schools.jpg';
import shapeImg3 from '../../assets/img/contact/ICSE-Schools.jpg';
import shapeImg4 from '../../assets/img/contact/distributors.jpg';
import shapeImg5 from '../../assets/img/contact/Stationery-distributors.jpg';

import teamImg1 from '../../assets/img/team/team-4-1.jpg';
import teamImg2 from '../../assets/img/team/team-4-2.jpg';
import teamImg3 from '../../assets/img/team/team-4-3.jpg';
import teamImg4 from '../../assets/img/team/team-4-4.jpg';
import teamImg5 from '../../assets/img/team/team-4-5.jpg';
import teamImg6 from '../../assets/img/team/team-4-6.jpg';
import teamImg7 from '../../assets/img/team/team-4-7.jpg';
import teamImg8 from '../../assets/img/team/team-4-8.jpg';

const Clients = () => {
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Our Esteemed Clients" />
      <div className="ed-team-area p-relative inner-style fix z-index pt-110 pb-90">
        <div className="container">
          <div className="it-team-title-wrap mb-40">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-10">
                <h4>International Schools</h4>
              <img src={shapeImg} alt="" />
              <h4 className="pt-30">CBSE Schools</h4>
              <img src={shapeImg2} alt="" />
              <h4 className="pt-30">ICSE Schools</h4>
              <img src={shapeImg3} alt="" />
              <h4 className="pt-30">Distributors for..</h4>
              <img src={shapeImg4} alt="" />
              <h4 className="pt-30">Stationery Distributors for..</h4>
              <img src={shapeImg5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </main>
  );
};
export default Clients;