/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import HeaderFour from '../../components/Header/HeaderFour';
import FooterTwo from '../../components/Footer/FooterTwo';
import Breadcrumb from '../../components/Breadcrumb';
import Logo from '../../assets/img/logo/logo-white-2.png';
import shapeImg1 from '../../assets/img/about/about-3-shap-1.png';
import titleImg from '../../assets/img/category/title.svg';
import SectionTitle from '../../components/SectionTitle';
import examinarlogo from '../../assets/img/about/prokid.jpg';
import shapeImg2 from '../../assets/img/about/ed-shape-3-1.png';
import aboutImg1 from '../../assets/img/about/Bookfair.jpeg';
import aboutImg2 from '../../assets/img/about/thumb-4-2.jpg';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import shapeImg from '../../assets/img/contact/shape-2-1.png';

const BookFairEvents = () => {
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, []);
  const items = [
    {
      icon: 'flaticon-study',
      title: 'Duration',
      description:
        'We propose to hold the Book Fair for a period of 3 days. Out of these, two days will be dedicated for browsing and exploring the vast collection of books.',
    },
    {
      icon: 'flaticon-exam',
      title: 'Book Collection',
      description:
        'Central Books will curate a diverse range of books from various publishers to provide a comprehensive array of educational resources.',
    },
    {
      icon: 'flaticon-puzzle',
      title: 'Benefits to Partner Schools',
      description:
        'As our esteemed partner, we are committed to ensure maximum benefits for your institution.',
    },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef();
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  
  return (
    <main>
      <HeaderFour />
      <Breadcrumb title="Book Fair & Events" subTitle="Book Fair & Events" />

      <div id="it-about" className="it-about-3-area it-about-4-style p-relative grey-bg pt-120 pb-120">
        <div className="ed-about-3-shape-2">
          <img src={shapeImg1} alt="" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 pb-30">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <img
                  src={examinarlogo}
                  alt=""
                  width="320px"
                />
              </div>
              <SectionTitle
                itemClass="it-about-3-title-box"
                subTitleClass="it-section-subtitle-5 orange"
                subTitle="Book Fair & Events"
                titleClass="it-section-title-3 pb-30"
                title="Enhance the educational experience of students"
                titleImage={titleImg}
                description="We at Central Books have catered to the academic requirements of esteemed educational organizations since our establishment in 1947. Our commitment in providing top-quality educational products, including School Textbooks, Notebooks, General Books and Stationery, has made us the leading distributor in this field."
              />
              <h4>Empowering Students and Professionals</h4>
              <p>
              We are excited to announce that we are expanding our services to schools by organizing Book Fairs under the brand name of Prokids during the ongoing academic year. This initiative aims to further enhance the educational experience of students and provide an engaging platform for exploring a wide range of literature.
              </p>
            </div>
         <div className="ed-header-button text-center d-none d-lg-block">
                       <Link className="ed-btn-theme theme-2" data-tally-open="3XzNaO" data-tally-layout="modal" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">
                       Download Brochure
                         <i className="fa-solid fa-arrow-down"></i>
                       </Link>
                     </div>
                     <p>&nbsp;</p>
        <div className="col-xl-5 col-lg-5">
              <div className="ed-about-3-thumb-wrap p-relative">
                <div className="ed-about-3-thumb">
                  <img src={aboutImg1} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7">
              <div className="it-about-3-mv-box">
                <div className="row">
                  <h4>Here are some key details and terms and conditions regarding the proposed Book Fair:</h4>
                  {items.map((items, index) => (
                    <div key={index} className="col-xl-12">
                      <div className="it-about-4-list-wrap d-flex align-items-start">
                        <div className="it-about-4-list-icon">
                          <span>
                            <i className={items.icon}></i>
                          </span>
                        </div>
                        <div>
                          <span className="it-about-3-mv-title">
                            {items.title}
                          </span>
                          <p>{items.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            </div>
            <div>
              <p>We kindly request you to suggest a convenient date and time for a meeting during which, we can delve into the details like, terms and conditions address queries you may have and collectively finalize the arrangements for the Book Fair.</p>
              <p>We believe that this collaboration will further strengthen our mutual commitment in providing quality educational resources to the students we serve.</p>
              <p>Thank you for your time and consideration. We eagerly anticipate your positive response and look forward to meet you and make this collaboration successful.</p>
            </div>
        </div>
        </div>
        <div className="it-contact__area pt-120 pb-120">
        <div className="container">
          <div className="it-contact__wrap fix z-index-3 p-relative">
            <div className="it-contact__shape-1 d-none d-xl-block">
              <img src={shapeImg} alt="" />
            </div>
            <div className="row align-items-end">
              <div className="col-xl-7">
                <div className="it-contact__right-box">
                  <div className="it-contact__section-box pb-20">
                    <h4 className="it-contact__title pb-15">
                      Get in Touch with us!
                    </h4>
                    <p>
                      Contact us today to learn more about Book Fairs. <br />
                      Please let us know your requirements, <br />we're Happy to
                      Assist you.
                    </p>
                  </div>
                  <div className="it-contact__content mb-55">
                    <ul>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Our Address</span>
                            <a
                              target="_blank"
                              href="https://maps.app.goo.gl/MudRAwKrP5EceSrWA" rel="noreferrer"
                            >
                              CBS HUB Private Limited<br/>
                              6-3-553, C1, 2nd Floor, Quena Square, Erramanzil,
                              Somajiguda, <br/>Hyderabad-500 082.
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-clock"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>Hours of Operation</span>
                            <a href="#">Mon - Sat: 10.00am to 7.00pm</a>
                            <span>Sunday is Holiday</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="it-contact__list d-flex align-items-start">
                          <div className="it-contact__icon">
                            <span>
                              <i className="fa-solid fa-phone phone"></i>
                            </span>
                          </div>
                          <div className="it-contact__text">
                            <span>contact</span>
                            <a href="tel:04024098282">040 2409 8282</a>
                            <a href="tel:919100096937">+91 91000 96937</a>
                            <a href="mailto:prokid@centralbooks.in">
                            prokid@centralbooks.in
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 it-contact__form-box">
                <div className="container mt-4">
                    <iframe
                      data-tally-src="https://tally.so/embed/wgvzzO?alignLeft=1&hideTitle=1&transparentBackground=1"
                      loading="lazy"
                      width="100%"
                      height="464"
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      title="Book Fair & Events"
                      style={{
                        borderRadius: "5px",
                        padding: "15px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    ></iframe>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo
        footerClass="it-footer-area it-footer-bg it-footer-style-5 ed-footer-style-5 inner-style black-bg pb-70"
        footerLogo={Logo}
        btnClass="it-btn-white sky-bg"
        copyrightTextClass="it-copyright-text inner-style text-center"
      />
    </main>
  );
};

export default BookFairEvents;
