import React from 'react';
import SingleWork from '../../components/Work';

import iconImg1 from '../../assets/img/faq/1.svg';
import iconImg2 from '../../assets/img/faq/2.svg';
import iconImg3 from '../../assets/img/faq/3.svg';

const Work = () => {
  return (
    <div
      id="it-work"
      className="it-wrok-area fix it-wrok-bg ed-work-style-2 ed-work-style-3 pt-10 pb-90"
    >
      <div className="container container-3">
        <div className="row">
          <div
            className="col-xl-4 col-lg-4 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration=".9s"
            data-wow-delay=".3s"
          >
            <SingleWork
              itemClass="it-work-item"
              iconImage={iconImg1}
              title="250+ Professionals Team"
              description="We have sufficient man-power of around 250 people to ensure timely supplies. We are equipped with high quality, latest machines which help in making customized kits class wise for schools there by ensuring timely supply to schools."
            />
          </div>
          <div
            className="col-xl-4 col-lg-4 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration=".9s"
            data-wow-delay=".5s"
          >
            <SingleWork
              itemClass="it-work-item"
              iconImage={iconImg2}
              title="Best in Educational Industry"
              description="We prioritize our customers by offering personalized supply chain management. Each major client is assigned a dedicated Fulfillment Manager as a single point of contact, ensuring smooth communication, understanding school requirements, & delivering prompt service until procurement is complete."
            />
          </div>
          <div
            className="col-xl-4 col-lg-4 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration=".9s"
            data-wow-delay=".7s"
          >
            <SingleWork
              itemClass="it-work-item"
              iconImage={iconImg3}
              title="75+ Years of Educational Service"
              description="Since 1948, We are providing the educational services and Central books has a spacious 40 thousand square feet warehouse to enable centralized management of predetermined sufficient inventory levels."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Work;
