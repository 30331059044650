import React from 'react';
import SectionTitle from '../../components/SectionTitle';

import shapeImg1 from '../../assets/img/choose/BGbulksupplies.png';
import shapeImg2 from '../../assets/img/choose/shape-1-8.png';
import shapeImg3 from '../../assets/img/choose/shape-1-9.png';
import shapeImg4 from '../../assets/img/choose/shape-1-10.png';
import shapeImg5 from '../../assets/img/choose/shape-1-5.png';
import shapeImg6 from '../../assets/img/choose/shape-1-6.png';
import chooseImg from '../../assets/img/choose/schoolbooks.png';
import { Button } from 'bootstrap';

const ServiceBulkSupplies = () => {
  const items = [
    {
      icon: 'flaticon-book ',
      title: 'Bulk Supplies & Kits',
      description:
        'We supply packed book kits, which include textbooks, notebooks, and stationery— “All in one kit”.',
      button: '/bulksupplieskits',
    },
    {
      icon: 'flaticon-shopping-cart',
      title: 'E-commerce Online Services',
      description:
        'CBS caters to customers online needs by selling books and kits through its website.',
        button: '/ecommerceservices',
    },
    {
      icon: 'flaticon-skill',
      title: 'Tuck Shop Sales',
      description:
        'We handhold our salespeople in the school stalls and maintain the selling process on behalf school.',
        button: '/tuckshopsales',
    },
  ];
  return (
    <div className="it-choose-area it-choose-style-2 z-index fix p-relative grey-bg pt-180 pb-110">
      <div className="it-choose-shape-5 d-none d-xl-block">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="it-choose-shape-6 d-none d-xl-block">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="it-choose-shape-7 d-none d-xl-block">
        <img src={shapeImg3} alt="" />
      </div>
      <div className="it-choose-shape-8 d-none d-xl-block">
        <img src={shapeImg4} alt="" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 mb-30">
            <div className="it-choose-thumb-box text-center text-lg-end">
              <div className="it-choose-thumb p-relative">
                <img src={chooseImg} alt="" />
                <div className="it-choose-shape-1">
                  <img src={shapeImg5} alt="" />
                </div>
                <div className="it-choose-shape-2">
                  <img src={shapeImg6} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 mb-30">
            <div className="it-choose-left">
              <SectionTitle
                itemClass="it-choose-title-box mb-30"
                subTitleClass="it-section-subtitle-2 white-bg"
                subTitle="OUR SERVICES"
                titleClass="ed-section-title"
                title="Bulk Supplies & Kits"
              />
              <div className="it-choose-content-box">
                {items.map((item, index) => (
                  <a
                    key={index}
                    href={item.button}
                    className="it-choose-content d-flex align-items-center mb-30"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div className="it-choose-icon">
                      <span>
                        <i className={item.icon}></i>
                      </span>
                    </div>
                    <div className="it-choose-text highlight-title green">
                      <h4 className="it-choose-title">
                        <span>{item.title}</span>
                      </h4>
                      <p className="mb-0">{item.description}</p>
                      <div className="ed-course-price-box service-know-btn">
                        <span>Know More..</span>
                        <span>
                          <svg
                            width="21"
                            height="8"
                            viewBox="0 0 21 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceBulkSupplies;
